import React from "react";
import { XrComponentKey, XrComponentProps } from "../types";
import XrSceneComponent from "./XrSceneComponent";



// eslint-disable-next-line @typescript-eslint/ban-types
abstract class XrComponent<Props extends XrComponentProps = XrComponentProps, State = {}> extends React.Component<Props, State> {
	static readonly componentKey: XrComponentKey;
	static readonly sceneComponent: typeof XrSceneComponent;

}

export default XrComponent;
