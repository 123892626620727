import { createContext, useState, useContext } from 'react';


// eslint-disable-next-line no-unused-vars
function createAppContext<stateType, actionsType>(initialValue: stateType, actions: (setState: React.Dispatch<React.SetStateAction<stateType>>) => actionsType){
	
	const StateContext = createContext<stateType>(initialValue);
	const ActionContext = createContext(actions(()=>null));


	
	const Provider = ({ children, value }: React.PropsWithChildren<{value?:stateType}>) => {
		const _initialValue = value || initialValue;
		const [state, setState] = useState<stateType>(_initialValue);
		// if (state != _initialValue)
		// 	setState(_initialValue);
		

		return (
			<ActionContext.Provider value={actions(setState)}>
				<StateContext.Provider value={state}>
					{children}
				</StateContext.Provider>
			</ActionContext.Provider>
		)
	}

	function useValue() {
		const value = useContext(StateContext);
		if (value === undefined)
			throw new Error('StateContext not defined');
		return useContext(StateContext);
	}

	function useActions() {
		const value = useContext(ActionContext);
		if (value === undefined || value === null)
			throw new Error('ActionContext not defined');
		return useContext(ActionContext);
	}

	return { Provider, useValue, useActions, StateContext, ActionContext };
}

export default createAppContext;
