import { MainAppComponentOptionList } from "@/components/xr/xr.types";
import { ManagerStatus, ViewerStatus } from "@/libs/xrsdk/enums";
import { MpSdk } from "@matterport/webcomponent";
import { XrComponentCustomProps, XrComponentKey, XrComponentOptionType, XRManagerInitialData } from "@xrsdk/types";
import XrViewer from "@xrsdk/XrViewer";
import React from "react";
import { Observable } from "utils/Observable";
import XrComponent from "./abstracts/XrComponent";
import { Transformable } from "./utils/Transformable";


export interface XrManagerProps {
	onSdkConnected?: (sdk: MpSdk) => Promise<void>

	initialData: XRManagerInitialData
	components: XrComponentOptionType<MainAppComponentOptionList>[]
	componentCustomPropsDict?: { [key: XrComponentKey]: XrComponentCustomProps }
}
export interface XrManagerState {
	sdk: MpSdk
}

abstract class XrManager<P extends XrManagerProps = XrManagerProps, S extends XrManagerState = XrManagerState> extends React.Component<P, S>{
	private viewer!: XrViewer;
	state: S = {} as S;

	protected static possibleComponents: typeof XrComponent[];
	private static _registeredComponents: { [key: XrComponentKey]: typeof XrComponent } = {};

	private readonly _status: Observable<ManagerStatus>;
	protected get status(): XrManager<P, S>['_status']['value'] {
		return this._status.value;
	}
	protected set status(value: XrManager<P, S>['_status']['value']) {
		this._status.value = value;
	}

	protected get static(): typeof XrManager {
		return (this.constructor as typeof XrManager);
	}

	constructor(props:P) {
		super(props);
		this._status = new Observable<ManagerStatus>(ManagerStatus.UNINITIALIZED);
		this._status.subscribe(this.onStatusChange.bind(this));

		this.static.possibleComponents.forEach(component => {
			if (component.componentKey in this.static._registeredComponents) {
				console.warn(`이미 등록된 컴포넌트 ${component.componentKey}`);
				return;
				// throw Error(`이미 등록된 컴포넌트 ${component.componentKey}`);
			}
			this.static._registeredComponents[component.componentKey] = component;
		});
	}

	componentDidMount(): void {
		const $iframeContainer = document.querySelector("#iframeContainer");
		if (!$iframeContainer) return;

		const $viewer: MatterportViewer = (document.createElement("matterport-viewer") as MatterportViewer);

		this.viewer = new XrViewer($viewer, this.props.initialData.viewerOptions);
		this.viewer.status.subscribe(this.onViewerStatusChange.bind(this));

		$iframeContainer.appendChild($viewer);

		this.status = ManagerStatus.INITIALIZING;
	}

	private async onViewerStatusChange(status: ViewerStatus): Promise<void> {
		switch (status) {
			case ViewerStatus.ERROR:
				this.status = ManagerStatus.ERROR;
				break;
			case ViewerStatus.UNINITIALIZED:
				break;
			case ViewerStatus.CONNECTED:
				await this.initSdk(this.viewer.sdk);
				this.status = ManagerStatus.CONNECTED;
				break;
			case ViewerStatus.LOADING:
				await this._status.waitUntil(t => t === ManagerStatus.CONNECTED);
				this.status = ManagerStatus.LOADING;
				break;
			case ViewerStatus.LOADED:
				await this._status.waitUntil(t => t === ManagerStatus.LOADING);
				this.status = ManagerStatus.LOADED;
				break;
			case ViewerStatus.READY:
				await this._status.waitUntil(t => t === ManagerStatus.LOADED);
				this.status = ManagerStatus.READY;
				break;
		}
	}
	private async initSdk(sdk: MpSdk) {
		this.setState({ sdk });
		this.props.onSdkConnected?.(sdk);
		await Transformable.initControls(sdk);
	}
	protected async onStatusChange(status: ManagerStatus) {
		// if (status === ManagerStatus.CONNECTED) {
		// 	this.props.onSdkConnected?.(this.state.sdk);
		// }
	}

	render() {
		return (
			<>
				<div id='iframeContainer'></div>

				<div id="xrManagerElementContainer">
					{this.state.sdk && this.props.components.map(({ key: componentKey, props }, i) => {
						const Component = this.static._registeredComponents[componentKey] as any;
						if (!Component)
							throw new Error(`존재하지 않는 컴포넌트 ${componentKey}`);

						return (
							<Component key={`${componentKey}-${i}`}
								sdk={this.state.sdk}
								customProps={this.props.componentCustomPropsDict?.[componentKey]}
								{...props}
							/>
						);
					})}
				</div>
			</>
		)
	}
}

export default XrManager;
