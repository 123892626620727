import "@matterport/webcomponent";
import { MpSdk } from "@matterport/webcomponent/built-bundle/sdk";
import { Observable } from "utils/Observable";
import { ViewerStatus } from "@/libs/xrsdk/enums";
import { BaseViewerOptionsType, BaseViewerParamsType, Dict } from "@xrsdk/types";
import { updateUrlParams } from "./utils/common";


class XrViewer {
	public sdk!: MpSdk
	public readonly status: Observable<ViewerStatus>
	protected readonly $viewer: MatterportViewer;
	protected viewerOptions: BaseViewerOptionsType

	public constructor($viewer: XrViewer["$viewer"], viewerOptions: XrViewer["viewerOptions"]) {
		this.$viewer = $viewer;
		this.viewerOptions = viewerOptions;

		this.status = new Observable<ViewerStatus>(ViewerStatus.UNINITIALIZED);

		this.initPreCustomOptions(viewerOptions.customOptions);

		this.initViewer();
	}

	protected initPreCustomOptions(customOptions: BaseViewerOptionsType["customOptions"]): void {

		updateUrlParams({ useLegacyIds: "0" });

		if (customOptions["start"]) {
			// if (customOptions["start"]["sweep"])
			// 	this.viewerOptions.params["ss"] = customOptions["start"]["sweep"].toString();
			// if (customOptions["start"]["rotation"])
			// 	this.viewerOptions.params["sr"] = customOptions["start"]["rotation"].join(",");
			history.replaceState(null, "", `?ss=${customOptions["start"]["sweep"]}&sr=${customOptions["start"]["rotation"]?.join(",")}`);
		}
	}

	protected initPostCustomOptions(customOptions: BaseViewerOptionsType["customOptions"]): void {
		const $css = document.createElement("style");

		if (customOptions?.disableTagDock === true)
			this.sdk.Tag.toggleDocking(false);

		if (customOptions?.disableTagNav === true)
			this.sdk.Tag.toggleNavControls(false);

		if (customOptions?.disableTagShare === true)
			this.sdk.Tag.toggleSharing(false);

		if (customOptions?.disableMeasurement === true) {
			$css.innerHTML += ".measure-mode-button {display: none !important;}";
		}
		if (customOptions?.hideBottomText === true) {
			$css.innerHTML += ".showcase-footer>span, .showcase-footer>.mp-nova-btn-overlay {display: none !important;}";
		}
		if (customOptions?.hideFullscreen === true) {
			$css.innerHTML += "#bottom-ui .fullscreen-mode {display: none !important;}";
		}
		if (customOptions?.hidePoweredBy === true) {
			$css.innerHTML += "#loading-powered-by {opacity:0 !important;}";
		}

		this.$viewer.shadowRoot!.appendChild($css);
	}

	private async onViewerLoaded(sdk: MpSdk) {
		this.sdk = sdk;
		this.status.value = ViewerStatus.CONNECTED;

		const PHASE = this.sdk.App.Phase;
		const PHASES_MAP: { [key: string]: ViewerStatus } = {
			[PHASE.LOADING]: ViewerStatus.LOADING,
			[PHASE.STARTING]: ViewerStatus.LOADED,
			[PHASE.PLAYING]: ViewerStatus.READY,
			[PHASE.ERROR]: ViewerStatus.ERROR,
		}

		this.sdk.App.state.subscribe(((t: MpSdk.App.State): void => {
			// loading에서 loaded를 안거치고 ready로 뛰어넘는 경우 처리
			if (this.status.value === ViewerStatus.LOADING && t.phase === PHASE.PLAYING)
				this.status.value = ViewerStatus.LOADED;

			if (t.phase in PHASES_MAP)
				this.status.value = PHASES_MAP[t.phase];
			else
				console.warn(t.phase);
		}));

		this.initPostCustomOptions(this.viewerOptions.customOptions);
	}

	private initViewer(): void {
		for (const key in this.viewerOptions.params)
			this.$viewer.setAttribute(key, this.viewerOptions.params[key]);


		this.$viewer.addEventListener("mpSdkConnected", async e => {
			await this.onViewerLoaded(e.detail.mpSdk);
		});
	}
}

export default XrViewer;
