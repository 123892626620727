import { useEffect, useState } from "react";
import { MpSdk } from "@matterport/webcomponent";


type UseViewModeParams = {
	sdk: MpSdk | null
}
const useCurrentSweep = ({ sdk }: UseViewModeParams) => {
	const [currentSweep, setCurrentSweep] = useState<SweepDataType | null>(null);
	const onChange: (_: string, newMode: string) => void = (_, newMode) => {
		if (!sdk) return;

		if (newMode !== sdk.Mode.Mode.INSIDE)
			setCurrentSweep(null);
	};

	useEffect(() => {
		if (!sdk) return;

		sdk.on(sdk.Mode.Event.CHANGE_END, onChange);

		const subs = sdk.Sweep.current.subscribe((sweep) => {
			if (!sweep.id || currentSweep?.id === sweep.id) return;
			setCurrentSweep({
				id: sweep.id,
				position: Object.assign({}, sweep.position),
			});
		});

		return () => {
			sdk.off(sdk.Mode.Event.CHANGE_END, onChange);
			subs.cancel();
		}
	}, [sdk]);

	return currentSweep;
}

export default useCurrentSweep;
