import { MpSdk } from '@matterport/webcomponent';
import { memoize } from 'lodash';
import * as THREE from 'three';

const _getSweepYCorrection = memoize((sdk: MpSdk) => {
	let resolvable: (value: number) => void;
	const p = new Promise<number>((resolve) => {
		resolvable = resolve;
	});


	resolvable!(1.45);

	return p;
});


type FindRouteParams = {
	sdk: MpSdk
	startSweepId: string
	endSweepId: string
	excludedSweepIds?: string[]
}
export const findRoute = async ({ sdk, startSweepId, endSweepId, excludedSweepIds = [] }: FindRouteParams) => {
	const sweepGraph = await sdk.Sweep.createGraph();
	const excludedVertices = excludedSweepIds.map(id => sweepGraph.vertex(id)).filter(v => !!v) as MpSdk.Graph.Vertex<MpSdk.Sweep.ObservableSweepData>[];
	sweepGraph.removeVertex(...excludedVertices);

	// sweepGraph.removeEdge(excludedSweepIds);

	for (const { src, dst, weight } of sweepGraph.edges) {
		sweepGraph.setEdge({ src, dst, weight: weight ** 2 });
	}

	const startSweep = sweepGraph.vertex(startSweepId);
	if (!startSweep)
		throw new Error(`Sweep ${startSweepId} not found`);

	const endSweep = sweepGraph.vertex(endSweepId);
	if (!endSweep)
		throw new Error(`Sweep ${endSweepId} not found`);


	const aStarRunner = sdk.Graph.createAStarRunner(sweepGraph, startSweep, endSweep).exec();
	if (aStarRunner.status !== "astar.status.success")
		throw new Error(`Failed to find route from ${startSweepId} to ${endSweepId}`);

	const path = aStarRunner.path;

	return path.map(p => p.data);
}


type FindRoutesParams = {
	sdk: MpSdk
	sweepIds: string[]
	excludedSweepIds?: string[]
}
export const findRoutes = async ({ sdk, sweepIds, excludedSweepIds = [] }: FindRoutesParams): Promise<THREE.Vector3[]> => {
	let startSweepId: string;
	[startSweepId, ...sweepIds] = sweepIds;

	const positions: THREE.Vector3[] = [];

	for (let i = 0; i < sweepIds.length; i++) {
		const sweepId = sweepIds[i];
		const path = await findRoute({ sdk, startSweepId, endSweepId: sweepId, excludedSweepIds });
		positions.push(...path.map(p => new THREE.Vector3(p.position.x, p.position.y, p.position.z)).splice(i === 0 ? 0 : 1));
		startSweepId = sweepId;
	}

	// 높이가 공중에 뜨는 현상 보정
	const yCorrection = await _getSweepYCorrection(sdk);
	positions.map(p => p.sub(new THREE.Vector3(0, yCorrection, 0)));

	return positions;
}
