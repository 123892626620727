import { addCube, centerScaleToStartEnd, xyzToVector3 } from "@/libs/xrsdk/utils/threeUtils";
import { MpSdk } from "@matterport/webcomponent";
import { debounce, throttle } from "lodash";
import { ComponentInteractionType } from "@xrsdk/enums";
import { Transformable } from "@/libs/xrsdk/utils/Transformable";
import CubeSceneComponent from "@/libs/xrsdk/utils/debug/CubeSceneComponent";
import * as THREE from "three";

/* 
export const findFloorByFloorId = (floorList: FloorListItemType[], floorId: string): FloorListItemType | null => {
	return floorList.find(floor => floor.floorId === floorId) || null;
} */










//------------------------ Debugging Utils ------------------------//
export type DebugFunctionType = (sdk: MpSdk) => Promise<() => void>

export const debugCurrentSweepInfo: DebugFunctionType = async (sdk: MpSdk) => {
	const subs = sdk.Sweep.current.subscribe(sweep => {
		console.info("debugCurrentSweepInfo", sweep)
	});
	return () => {
		subs.cancel();
	}
}

export const debugCameraInfo: DebugFunctionType = async (sdk: MpSdk) => {
	const subs = sdk.Camera.pose.subscribe(debounce(pose => {
		console.info("debugCameraInfo", pose)
	}, 300));

	return () => {
		subs.cancel();
	}
}
export const debugSweepXYInfo: DebugFunctionType = async (sdk: MpSdk) => {
	const subs = sdk.Camera.pose.subscribe(debounce((pose: MpSdk.Camera.Pose) => {
		console.info(JSON.stringify({
			sweepId: pose.sweep,
			rotation: Object.entries(pose.rotation).reduce((acc, [key, value]) => {
				acc[key] = Math.round(value * 100) / 100;
				return acc;
			}, {} as Record<string, number>)
		}));
	}, 300));

	return () => {
		subs.cancel();
	}
}

export type PointerEventsType = "move" | "click" | "space";

export const debugPointer: DebugFunctionType = async (sdk: MpSdk) => {
	const subs = sdk.Pointer.intersection.subscribe(throttle(data => {
		console.info("debugPointer", data);
	}, 300));

	return () => {
		subs.cancel();
	}
}

export const debugUserInput: DebugFunctionType = async (sdk: MpSdk) => {
	let pointerData: MpSdk.Pointer.Intersection | null = null;
	const subs1 = sdk.Pointer.intersection.subscribe(data => {
		pointerData = data;
	});

	const [sceneObject] = await sdk.Scene.createObjects(1);
	const node = sceneObject.addNode();
	const inputComponent = node.addComponent('mp.input', {
		eventsEnabled: true,
		// userNavigationEnabled: false,
	});
	const scene = inputComponent.context.scene;
	sceneObject.start();

	/*
	// 마우스 처리 예시
	const clickEmitPath = sceneObject.addEmitPath(inputComponent, "INTERACTION.CLICK");
	sceneObject.spyOnEvent({
		path: clickEmitPath,
		onEvent(payload: any) {
			console.info(pointerData);
		}
	});
	*/

	// 키보드 처리 예시
	const keyEmitPath = sceneObject.addEmitPath(inputComponent, "INTERACTION.KEY");
	const subs2 = sceneObject.spyOnEvent({
		path: keyEmitPath,
		onEvent(payload: any) {
			if (pointerData === null) return;

			if (payload.key === 32 && payload.state === 0) {
				addCube({ scene, position: xyzToVector3(pointerData.position) });
			}
		},
	});

	return () => {
		subs1.cancel();
		subs2.cancel();
		sceneObject.stop();
	}
}

export const debugRoom: DebugFunctionType = async (sdk: MpSdk) => {
	let currentFloor: number | null = null;
	const subs1 = sdk.Floor.current.subscribe(floor => {
		if (floor.id !== undefined)
			currentFloor = Number(floor.id);
		else
			currentFloor = null;
	});

	// let allRoomIds: number[] = [];
	const subs2 = sdk.Room.data.subscribe({
		onCollectionUpdated: function (data) {
			console.info("all rooms", data)
			// allRoomIds = Object.values(data).map(room => Number(room.id));
		}
	});
	const subs3 = sdk.Room.current.subscribe(data => {
		const currentRooms = data.rooms.filter(room => Number(room.floorInfo.id) == currentFloor);//.map(room => room.id);
		// const currentRooms = data.rooms.filter(room => allRoomIds.includes(Number(room.id)));//.map(room => room.id);
		console.info("current rooms", currentRooms)
	});

	return () => {
		subs1.cancel();
		subs2.cancel();
		subs3.cancel();
	}
}

export const debugTagPosition: DebugFunctionType = async (sdk: MpSdk) => {
	let pointerData: MpSdk.Pointer.Intersection | null = null;
	const subs1 = sdk.Pointer.intersection.subscribe(data => {
		pointerData = data;
	});

	const [sceneObject] = await sdk.Scene.createObjects(1);
	const node = sceneObject.addNode();
	const inputComponent = node.addComponent('mp.input', {
		eventsEnabled: true,
		// userNavigationEnabled: false,
	});
	const scene = inputComponent.context.scene;
	sceneObject.start();

	/*
	// 마우스 처리 예시
	const clickEmitPath = sceneObject.addEmitPath(inputComponent, "INTERACTION.CLICK");
	sceneObject.spyOnEvent({
		path: clickEmitPath,
		onEvent(payload: any) {
			console.info(pointerData);
		}
	});
	*/

	// 키보드 처리 예시
	const keyEmitPath = sceneObject.addEmitPath(inputComponent, "INTERACTION.KEY");
	const subs2 = sceneObject.spyOnEvent({
		path: keyEmitPath,
		onEvent(payload: any) {
			if (pointerData === null) return;

			if (payload.key === 32 && payload.state === 0) {
				addCube({ scene, position: xyzToVector3(pointerData.position) });
				console.info(pointerData.position);
				navigator.clipboard.writeText(`{x: ${pointerData.position.x.toFixed(2)}, y: ${pointerData.position.y.toFixed(2)}, z: ${pointerData.position.z.toFixed(2)}}`);
			}
		},
	});

	return () => {
		subs1.cancel();
		subs2.cancel();
		sceneObject.stop();
	}
}




export const debugBoundary: DebugFunctionType = async (sdk: MpSdk) => {
	/* let pointerData: MpSdk.Pointer.Intersection | null = null;
	const subs1 = sdk.Pointer.intersection.subscribe(data => {
		pointerData = data;
	}); */

	const [sceneObject] = await sdk.Scene.createObjects(1);
	const node = sceneObject.addNode();
	const inputComponent = node.addComponent('mp.input', {
		eventsEnabled: true,
		userNavigationEnabled: false,
	});
	node.start();

	const subs_cubeComponent = await sdk.Scene.register("cubeSceneComponent", () => { return new CubeSceneComponent() });

	// 키보드 처리 예시
	const keyEmitPath = sceneObject.addEmitPath(inputComponent, ComponentInteractionType.KEY);
	const subs3 = sceneObject.spyOnEvent({
		path: keyEmitPath,
		async onEvent(payload: any) {
			// if (pointerData === null) return;

			if (payload.key === 32 && payload.state === 0) {


				const [sceneObject] = await sdk.Scene.createObjects(1);
				const node = sceneObject.addNode();
				const cubeComponent = node.addComponent("cubeSceneComponent");

				new Transformable({
					component: cubeComponent, scene: sceneObject, node, onSelected: (box) => {
						if (!cubeComponent.outputs.objectRoot) return;
						const startend = centerScaleToStartEnd(box.position, box.scale)
						console.log(JSON.stringify({ start: startend[0], end: startend[1] }));
					}
				});
				node.start();

				// navigator.clipboard.writeText(`{x: ${pointerData.position.x.toFixed(2)}, y: ${pointerData.position.y.toFixed(2)}, z: ${pointerData.position.z.toFixed(2)}}`);
			}
		},
	});


	return () => {
		// subs1.cancel();
		// subs2.cancel();
		subs3.cancel();
		// sceneObject.stop();
		node.stop();
		subs_cubeComponent?.dispose();
	}
}
