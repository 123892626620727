import * as THREE from "three";

export default class PathGeometry extends THREE.BufferGeometry {

    constructor(curve: THREE.CatmullRomCurve3, size: number, stepFactor: number) {
        super();

        const steps = Math.floor(curve.getLength() * stepFactor);
        const curvePoints = curve.getPoints(steps);

        const leftSidePoints = [];
        const rightSidePoints = [];

        for (let i = 0; i < curvePoints.length; i++) {
            const u = i / (curvePoints.length - 1);
            const point = curvePoints[i];
            const tangent = curve.getTangent(u).normalize();

            const normal = new THREE.Vector3(-tangent.z, 0, tangent.x).normalize();

            const leftPoint = point.clone().sub(normal.clone().multiplyScalar(size / 2));
            const rightPoint = point.clone().add(normal.clone().multiplyScalar(size / 2));

            leftSidePoints.push(leftPoint);
            rightSidePoints.push(rightPoint);
        }

        const vertices = [];
        const uvs = [];

        for (let i = 0; i < leftSidePoints.length - 1; i++) {
            const lp1 = leftSidePoints[i];
            const rp1 = rightSidePoints[i];
            const lp2 = leftSidePoints[i + 1];
            const rp2 = rightSidePoints[i + 1];

            vertices.push(lp1.x, lp1.y, lp1.z);
            vertices.push(rp1.x, rp1.y, rp1.z);
            vertices.push(lp2.x, lp2.y, lp2.z);

            vertices.push(lp2.x, lp2.y, lp2.z);
            vertices.push(rp1.x, rp1.y, rp1.z);
            vertices.push(rp2.x, rp2.y, rp2.z);

            uvs.push(0, i / (leftSidePoints.length - 1));
            uvs.push(1, i / (leftSidePoints.length - 1));
            uvs.push(0, (i + 1) / (leftSidePoints.length - 1));

            uvs.push(0, (i + 1) / (leftSidePoints.length - 1));
            uvs.push(1, i / (leftSidePoints.length - 1));
            uvs.push(1, (i + 1) / (leftSidePoints.length - 1));
        }

        this.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        this.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
    }
}
