import { useEffect, useState } from "react";
import { PUBLIC_PATH } from "@/utils/config";
import Button from "../common/Button";
import { Loader2Icon } from "lucide-react";

type IntroProps = {
	isLoading: boolean
};

const Intro: React.FC<IntroProps> = ({ isLoading: _isLoading }: IntroProps) => {
	const [isLoading, setIsLoading] = useState(_isLoading);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isFinished, setIsFinished] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsLoading(_isLoading);
		}, 1000);
	}, [_isLoading]);

	const handleStart = () => {
		if (isPlaying) return;
		setIsPlaying(true);
		setTimeout(() => {
			setIsFinished(true);
		}, 800);
	}




	if (isFinished) return null;

	return (
		<div className={`fixed inset-0 bg-gray-600 z-50 transition delay-100 duration-700 ${isPlaying ? "opacity-0 scale-125 pointer-events-none" : "opacity-100"}`}>
			<div className="relative flex flex-col max-w-md w-full mx-auto h-full justify-center z-30 gap-20 px-5">
				<div className="flex flex-col gap-3 text-start text-white">
					<h1 className="text-2xl font-semibold">영인산산림박물관</h1>
					<h2 className="text-5xl font-bold">VR 가상체험</h2>
				</div>
				{isLoading ? (
					<div className="flex flex-col items-center justify-center text-center gap-5">
						<Loader2Icon className="text-white animate-spin text-3xl" />
						<span className="text-muted text-lg">
							데이터를 불러오는 중입니다.<br />잠시만 기다려주세요.
						</span>
					</div>
				) : (
					<Button onClick={handleStart} className="font-semibold text-xl h-12 mt-10">
						관람 시작
					</Button>
				)}

			</div>
			<div className="absolute inset-0 w-full h-full z-20 bg-gradient-to-t from-black/90 to-black/30" />
			<img src={PUBLIC_PATH + "/img/intro.jpg"} className="absolute inset-0 z-10 object-cover w-full h-full blur-sm" />
		</div>
	);
}

export default Intro;
