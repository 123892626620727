import { FlaskConicalIcon, InfoIcon, MapPinIcon } from "lucide-react";
import { TextImageInfoItemType } from "../../TagComponent";

type TagComponentImageTextInfoProps = TextImageInfoItemType

const TagComponentImageTextInfo: React.FC<TagComponentImageTextInfoProps> = ({ content, image, infoText, mapPinText, chemicalText, subTitle, title, limitImageHeight = true }: TagComponentImageTextInfoProps) => {
	return (
		<div className="flex flex-col gap-5 ">
			<div className="text-center">
				<div className="text-xl font-semibold">{title}</div>
				{subTitle &&
					<div className="text-sm font-light text-neutral-400">{subTitle}</div>
				}
			</div>

			{image ?
				<div className="flex justify-center">
					<a href={image} target="_blank" rel="noreferrer"><img src={image} className={`w-full rounded-3xl ${limitImageHeight ? "max-h-96" : ""} object-cover`} alt={title} /></a>
				</div>
				: null
			}

			<div className="flex flex-col gap-2 text-gray-500">
				{infoText &&
					<div className="flex gap-2 items-center">
						<InfoIcon />
						<span className="text-sm">{infoText}</span>
					</div>
				}
				{mapPinText &&
					<div className="flex gap-2 items-center">
						<MapPinIcon />
						<span className="text-sm">{mapPinText}</span>
					</div>
				}
				{chemicalText &&
					<div className="flex gap-2 items-center">
						<FlaskConicalIcon />
						<span className="text-sm">{chemicalText}</span>
					</div>
				}
			</div>

			<p className="font-light break-keep">
				{content}
			</p>
		</div>
	);
}

export default TagComponentImageTextInfo;
