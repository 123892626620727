import createAppContext from '@/utils/createAppContext';
import React from 'react';

type stateType = null

const actions = (setState: React.Dispatch<React.SetStateAction<stateType>>) => {
	const _actions = {
		set: (state: boolean) => {
			// setState(state ? "cursor-pointer" : "");
			const $matterportViewer = document.querySelector<MatterportViewer>("matterport-viewer");
			if (!$matterportViewer || !$matterportViewer.shadowRoot) return;
			const $viewerInner = $matterportViewer.shadowRoot.querySelector(".matterport-webcomponent");
			if (!$viewerInner) return;

			const toggleCursorPointer = () => $viewerInner.classList.toggle("cursor-pointer", state);

			if (state) // wait for next frame
				setTimeout(toggleCursorPointer, 0);
			else
				toggleCursorPointer();
		}
	};
	return _actions;
};

const GlobalCursorContext = createAppContext<stateType, ReturnType<typeof actions>>(null, actions);

export default GlobalCursorContext;
