

type ExhibitsModalTabPropsBtn = {
	selected?: boolean
} & React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>>;

const ExhibitsModalTabBtn: React.FC<ExhibitsModalTabPropsBtn> = ({ children, selected, ...props }: ExhibitsModalTabPropsBtn) => {
	return (
		<button role="tab" className="relative pb-2 group" aria-selected={selected} {...props}>
			<span className="text-gray-400 group-hover:text-gray-800 group-aria-selected:text-black group-aria-selected:font-semibold">{children}</span>
			<div className="hidden group-aria-selected:block absolute bottom-0 left-1/2 bg-primary rounded-full w-1.5 h-1.5" />
		</button>
	);
}

export default ExhibitsModalTabBtn;
