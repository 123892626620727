import { threshold } from "@/utils/mathUtils";
import { MpSdk } from "@matterport/webcomponent";
import { memoize } from "lodash";
import * as THREE from "three";
import { MaterialLikeType } from "../types";



export type XYZ = {x: number; y: number; z: number; };
export type SimpleXYZ = [number,number,number];

export const xyzToVector3 = (xyz: XYZ):THREE.Vector3 => new THREE.Vector3(xyz.x, xyz.y, xyz.z);
export const vector3ToXYZ = (v: THREE.Vector3):XYZ => ({x: v.x, y: v.y, z: v.z});
export const xyzToEuler = (xyz: XYZ):THREE.Euler => new THREE.Euler(xyz.x * Math.PI / 180, xyz.y * Math.PI / 180, xyz.z * Math.PI / 180, 'YXZ');
export const eulerToXYZ = (v: THREE.Euler):XYZ => ({x: v.x * 180 / Math.PI, y: v.y * 180 / Math.PI, z: v.z * 180 / Math.PI});


export type TransformType = {
	position: THREE.Vector3,
	rotation: THREE.Euler,
	scale: THREE.Vector3,
}


export const getSdkContext = (() => {
	let _sdk: MpSdk | null = null;
	let _context: MpSdk.Scene.IComponentContext | null = null;

	return async (sdk: MpSdk) => {
		if (_sdk !== sdk) {
			_sdk = sdk;
			const [sceneObject] = await sdk.Scene.createObjects(1);
			const node = sceneObject.addNode();
			
			const component = node.addComponent('mp.input');
			_context = component.context;
		}
		if (!_context) throw new Error('Context not initialized');
		return _context;
	}
})();

export const vector3ToMappedXY = (v3: THREE.Vector3):{x:number, y:number}=>{
	return {
		x: threshold((v3.x + 1) / 2, 0, 1),
		y: threshold(-(v3.y - 1) / 2, 0, 1),
	}
}

export const xyzToScreenXY = (xyz: XYZ, camera: THREE.Camera): { x: number, y: number } => {
	const v3 = xyzToVector3(xyz);
	v3.project(camera);
	return vector3ToMappedXY(v3);
}

export const xyzMerge = (a: XYZ, b: XYZ): XYZ => ({ x: a.x + b.x, y: a.y + b.y, z: a.z + b.z, });

export const parseTransform = (node:THREE.Object3D):TransformType=>{
	return {
		position: node.position,
		rotation: node.rotation,
		scale: node.scale,
	}
}

/* export const setTransform = (component:TransformType, transform:TransformType):void=>{
	component.position.copy(transform.position);
	component.rotation.copy(transform.rotation);
	component.scale.copy(transform.scale);
} */
export const copyTransform = (component:TransformType, transform:TransformType):void=>{
	component.position.copy(transform.position);
	component.rotation.copy(transform.rotation);
	component.scale.copy(transform.scale);
}

export const cloneTransform = (transform:TransformType):TransformType=>{
	return {
		position: transform.position.clone(),
		rotation: transform.rotation.clone(),
		scale: transform.scale.clone(),
	}
}

export const startEndToCenterScale = (start:THREE.Vector3, end:THREE.Vector3):[THREE.Vector3, THREE.Vector3]=>{
	return [
		start.clone().add(end).multiplyScalar(0.5),
		new THREE.Vector3(
			end.x - start.x,
			end.y - start.y,
			end.z - start.z,
		)
	]
}

export const centerScaleToStartEnd = (center:THREE.Vector3, scale:THREE.Vector3):[THREE.Vector3, THREE.Vector3]=>{
	const a = center.clone().sub(scale.clone().multiplyScalar(0.5));
	const b = center.clone().add(scale.clone().multiplyScalar(0.5));
	return [
		new THREE.Vector3(Math.min(a.x, b.x), Math.min(a.y, b.y), Math.min(a.z, b.z)),
		new THREE.Vector3(Math.max(a.x, b.x), Math.max(a.y, b.y), Math.max(a.z, b.z)),
	]
}

export const roundVector3 = (v: THREE.Vector3, precision = 2): THREE.Vector3 => {
	return new THREE.Vector3(
		Number(v.x.toFixed(precision)),
		Number(v.y.toFixed(precision)),
		Number(v.z.toFixed(precision)),
	);
}

export const xzDistance = (a:THREE.Vector3|XYZ, b:THREE.Vector3|XYZ):number=>{
	return Math.sqrt((a.x-b.x)**2 + (a.z-b.z)**2);
}

export const xyzDistance = (a:THREE.Vector3|XYZ, b:THREE.Vector3|XYZ):number=>{
	return Math.sqrt((a.x-b.x)**2 + (a.y-b.y)**2 + (a.z-b.z)**2);
}

type CreateCubeParams = {
	scale?: THREE.Vector3;
	color?: number;
	opacity?: number;
};
export const createCube = ({ scale, color = 0xff0000, opacity = 0.8, }: CreateCubeParams): THREE.Mesh => {
	const geometry = new THREE.BoxGeometry();
	const material = new THREE.MeshBasicMaterial({ color, opacity, transparent: true, });
	const cube = new THREE.Mesh(geometry, material);

	if (scale) cube.scale.copy(scale);

	return cube;
};

type AddCubeParams = {
	scene: THREE.Scene;
	position?: THREE.Vector3;
	scale?: THREE.Vector3;
	color?: number;
	opacity?: number;
};
export const addCube = ({ scene, position, scale, color = 0xff0000, opacity = 0.4, }: AddCubeParams): THREE.Mesh => {
	const cube = createCube({ scale, color, opacity });

	if (position) cube.position.copy(position);
	scene.add(cube);
	return cube;
};
/**
//  * @deprecated use loadImageTextureAsync instead
 */
export const loadImageTexture = (src: string): THREE.Texture => {
	const _getCachedTexture = memoize((src: string) => {
		return (new THREE.TextureLoader()).load(src, (texture) => {
			// 이미지 비율 설정. 필요시 사용
			// if (!texture.image.width || !texture.image.height) return;
			// texture.userData.aspectRatio = (texture.image.height / texture.image.width) || 1.0;
		}, () => { }, (err) => {
			console.error(`Image(${src}) load Error`, err);
		});
	}, (src) => src);

	return _getCachedTexture(src);
}

export const loadImageTextureAsync = async (src: string): Promise<THREE.Texture> => {
	const _getCachedTexture = memoize(async (src: string) => {
		const texture = await new THREE.TextureLoader().loadAsync(src);
		texture.userData.aspectRatio = (texture.image.height / texture.image.width) || 1.0;

		return texture;
	}, (src) => src);

	return _getCachedTexture(src);
}


export const interpolate = (x: number, { xStart = 0, xEnd = 1.0, yStart = 0, yEnd = 1.0, clampStart = true, clampEnd = true }) => {
	let t = THREE.MathUtils.inverseLerp(xStart, xEnd, x);
	if (clampStart && t < 0) t = 0;
	if (clampEnd && t > 1) t = 1;
	return THREE.MathUtils.lerp(yStart, yEnd, t);
}

export const easeInOutQuad = (t: number): number => {
	return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}


export const parseMaterialLike = (materialLike: MaterialLikeType): THREE.Material => {

	let material: THREE.Material;
	if ('color' in materialLike && typeof materialLike.color === 'number') {
		material = new THREE.MeshBasicMaterial({ color: materialLike.color, ...materialLike.textureOptions });
	}
	else if ('imageUrl' in materialLike && typeof materialLike.imageUrl === 'string') {
		material = new THREE.MeshBasicMaterial({ map: loadImageTexture(materialLike.imageUrl), transparent: true, depthWrite: false, ...materialLike.textureOptions });
	}
	else if ('material' in materialLike && materialLike.material instanceof THREE.Material) {
		material = materialLike.material;
	}
	else
		throw new Error("Invalid path material");

	if (materialLike.userData)
		material.userData = materialLike.userData;

	return material;
}



export const correctYValues = (vertices: THREE.Vector3[]): void => {
	// 원본 데이터를 저장할 배열
	const originalVertices = vertices.map(vertex => vertex.clone());

	for (let i = 1; i < vertices.length - 1; i++) {
		const previousY = originalVertices[i - 1].y;
		const currentY = originalVertices[i].y;
		const nextY = originalVertices[i + 1].y;

		const averageY = (previousY + nextY) / 2;

		if (currentY < averageY) {
			vertices[i].y = averageY;
		}
	}
}

export function isWithinBoundary(point: XYZ, boundary: { start: XYZ, end: XYZ }): boolean {
	const minX = Math.min(boundary.start.x, boundary.end.x);
	const maxX = Math.max(boundary.start.x, boundary.end.x);
	const minY = Math.min(boundary.start.y, boundary.end.y);
	const maxY = Math.max(boundary.start.y, boundary.end.y);
	const minZ = Math.min(boundary.start.z, boundary.end.z);
	const maxZ = Math.max(boundary.start.z, boundary.end.z);

	return (
		point.x >= minX && point.x <= maxX &&
		point.y >= minY && point.y <= maxY &&
		point.z >= minZ && point.z <= maxZ
	);
}
