
const EXHIBITS_LIST_1: ExhibitsItemType[] = [
	{
		name: "자연 동양의 자연관",
		// subName: "Erinaceus amurensiskoreensis",
		mapPinText: "본관동 1층",
		image: "/img/exhibits/exhibition_img_01.jpg",
		sweepRotation: { "sweepId": "yf8ua1n5nxzupfweiphnyphwa", "rotation": { "x": 0, "y": -94.78 } }
	},
	{
		name: "나무(목재의 종류와 역사)",
		mapPinText: "본관동 1층",
		image: "/img/exhibits/exhibition_img_04.jpg",
		sweepRotation: { "sweepId": "u0frbsyw8ytp71pzgn0e4zwyb", "rotation": { "x": 0, "y": -57.01 } }
	},
	{
		name: "씨앗(자연의 시작 씨앗)",
		mapPinText: "본관동 1층",
		image: "/img/exhibits/exhibition_img_02.jpg",
		sweepRotation: { "sweepId": "dgbuc4qfn2a6aacuarq96ktid", "rotation": { "x": 0, "y": 138.19 } }
	},
	{
		name: "숲(아산의 자생식물)",
		mapPinText: "본관동 1층",
		image: "/img/exhibits/exhibition_img_03.jpg",
		sweepRotation: { "sweepId": "yga6t3ia3b3hzhdp5d0694cua", "rotation": { "x": 0, "y": -123.67 } }
	},
	{
		name: "생태계(동물들의 집짓기)",
		mapPinText: "본관동 1층",
		image: "/img/exhibits/exhibition_img_05.jpg",
		sweepRotation: { "sweepId": "cw75eht30eutuhcf174ei4s6c", "rotation": { "x": 0, "y": -14.63 } }
	},
	{
		name: "산림환경보전",
		mapPinText: "본관동 2층",
		image: "/img/exhibits/exhibition_img_12.jpg",
		sweepRotation: { "sweepId": "efd7t15x2cwcgzihw8m25nn9c", "rotation": { "x": -2.83, "y": -2.36 } }
	},
];

const EXHIBITS_LIST_2: ExhibitsItemType[] = [
	{
		name: "생각의 숲",
		mapPinText: "본관동 3층",
		image: "/img/exhibits/exhibition_img_07.jpg",
		sweepRotation: { "sweepId": "y2cqd7x6i5x4fq7yzq8ikcqhc", "rotation": { "x": -8.96, "y": 125.71 } }
	},
	{
		name: "광물·화석이야기",
		mapPinText: "본관동 3층",
		image: "/img/exhibits/exhibition_img_08.jpg",
		sweepRotation: { "sweepId": "0u3xrb73pgxxa55zqztdf2eha", "rotation": { "x": 4.58, "y": -59.68 } }
	},
	{
		name: "숲 체험관",
		mapPinText: "본관동 3층",
		image: "/img/exhibits/exhibition_img_09.jpg",
		sweepRotation: { "sweepId": "k34dfcygei3aui5y72kb18sxa", "rotation": { "x": -5.09, "y": 24.51 } }
	},
];

const EXHIBITS_LIST_3: ExhibitsItemType[] = [
	{
		name: "숲속 친구 새",
		mapPinText: "본관동 3층",
		image: "/img/exhibits/exhibition_img_10.jpg",
		sweepRotation: { "sweepId": "7i3gbungmdwuei2qutancqy8c", "rotation": { "x": 0.94, "y": 15.48 } }
	},
	{
		name: "트릭아트",
		mapPinText: "본관동 3층",
		image: "/img/exhibits/exhibition_img_11.jpg",
		sweepRotation: { "sweepId": "5ne9w63718y717t4xczxts9wa", "rotation": { "x": -0.07, "y": 154.17 } }
	},
];


export const SPACE_LIST: SpaceListItemType[] = [
	{
		title: "제1전시실",
		exhibitsList: EXHIBITS_LIST_1,
	},
	{
		title: "제2전시실",
		exhibitsList: EXHIBITS_LIST_2,
	},
	{
		title: "제3전시실",
		exhibitsList: EXHIBITS_LIST_3,
	},
]
