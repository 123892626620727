import App from '@/App';
import '@/styles/globals.css';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<App />
);

	// <React.StrictMode>
	// </React.StrictMode>
