import { cn } from "@/utils/cn";
import { XIcon } from "lucide-react";
import Plain from "../Plain";
import PlainModalBg from "./PlainModalBg";

type PlainModalProps = {
	closeModal?: () => void
	outerClassName?: string
	innerClassName?: string
} & React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

const PlainModal: React.FC<PlainModalProps> = ({ closeModal, className, outerClassName, innerClassName, children, ...props }: PlainModalProps) => {
	return (
		<div className={cn("absolute inset-0 p-3 flex items-center justify-center", outerClassName)}>
			<Plain className={cn("relative w-full z-10 p-6", className)} {...props}>
				<button className="absolute top-2 right-2 p-3 transition hover:opacity-50 text-xl" onClick={closeModal}><XIcon /></button>
				<div className={innerClassName}>
					{children}
				</div>
			</Plain>

			<PlainModalBg closeModal={closeModal} />
		</div>
	);
}

export default PlainModal;
