import { MoveToSweepFunctionType } from "@/pages/MainApp";
import { cn } from "@/utils/cn";
import { MpSdk } from "@matterport/webcomponent";
import { LayoutGridIcon, XIcon } from "lucide-react";
import { useState } from "react";
import ModalBg from "../common/ModalBg";
import Plain from "../common/Plain";
import ExhibitsList from "../ExhibitsList";
import ExhibitsModalTabBtn from "./ExhibitsModalTabBtn";
import ExhibitsModalTabPanel from "./ExhibitsModalTabPanel";

type ExhibitsModalProps = {
	toggleModal: () => void
	innerClassName?: string
	spaceList: SpaceListItemType[]
	moveToSweep: MoveToSweepFunctionType
} & React.HTMLAttributes<HTMLDivElement>;

const ExhibitsModal: React.FC<ExhibitsModalProps> = ({ spaceList, moveToSweep, className, innerClassName, toggleModal, ...props }: ExhibitsModalProps) => {

	const onClickMove = async (sweepRotation: SweepRotationType) => {
		const result = moveToSweep(sweepRotation); // await 
		if (result === undefined) return;

		toggleModal();
		return result;
	}

	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<div className={cn("absolute flex justify-center inset-0 p-5", className)} {...props}>
			<Plain className={cn("flex flex-col gap-7 max-w-header-width w-full z-10 p-6 max-sm:px-4", innerClassName)}>
				<div className="flex justify-between w-full">
					<div className="flex font-semibold items-center gap-1.5 text-gray-600">
						<LayoutGridIcon className="stroke-[2px] text-xl" />
						<span className="text-lg">전시 목록</span>
					</div>
					<button className="text-gray-500" onClick={toggleModal} aria-label="전시 목록 닫기">
						<XIcon className="text-lg" />
					</button>
				</div>
				<div>
					<div className="flex gap-4 overflow-y-hidden overflow-x-auto text-lg" role="tablist">
						{spaceList.map((space, i) => (
							<ExhibitsModalTabBtn key={i} selected={selectedTab === i} onClick={() => setSelectedTab(i)}>{space.title}</ExhibitsModalTabBtn>
						))}
					</div>
				</div>
				<div className="overflow-y-auto">
					<ExhibitsModalTabPanel><ExhibitsList exhibitsList={spaceList[selectedTab].exhibitsList} onClickMove={onClickMove} /></ExhibitsModalTabPanel>
				</div>
			</Plain>
			<ModalBg className="z-0" closeBg={toggleModal} />
		</div>
	);
}

export default ExhibitsModal;
