import Button from "@/components/common/Button";
import { MoveToSweepFunctionType } from "@/pages/MainApp";
import { FootprintsIcon, MapPinIcon } from "lucide-react";


type ExhibitsListItemProps = {
	exhibits: ExhibitsItemType
	onClickMove: MoveToSweepFunctionType
};

const ExhibitsListItem: React.FC<ExhibitsListItemProps> = ({ exhibits, onClickMove }: ExhibitsListItemProps) => {
	return (
		<div className="flex w-full p-3 rounded-xl bg-primary/10 gap-5 h-32">
			<div className="h-full aspect-square">
				<img src={exhibits.image} alt={exhibits.name} className="w-full h-full object-cover rounded-xl" />
			</div>
			<div className="flex flex-col justify-between w-full overflow-x-hidden">
				<div className="flex flex-col mt-1">
					<span className="text-black/90 text-lg font-semibold truncate">{exhibits.name}</span>
					{exhibits.subName ?
						<span className="text-sm font-light">{exhibits.subName}</span>
						: null
					}
					{exhibits.mapPinText &&
						<div className="flex text-gray-500 items-center gap-1.5 truncate">
							<MapPinIcon />
							<span>{exhibits.mapPinText}</span>
						</div>
					}

				</div>
				<div className="flex justify-between items-end w-full">
					<div className="ms-auto">
						<Button className="flex gap-1.5 items-center" onClick={() => onClickMove(exhibits.sweepRotation)}>
							<FootprintsIcon />
							<span>이동</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExhibitsListItem;

