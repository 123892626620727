import HeaderZoneListItem from "./HeaderZoneListItem";

type HeaderZoneListProps = {
	zoneList: ZoneListItemType[]
	onClickItem: (item: ZoneListItemType) => void
};

const HeaderZoneList: React.FC<HeaderZoneListProps> = ({ zoneList, onClickItem }: HeaderZoneListProps) => {
	return (
		<div className="overflow-y-auto pb-5">
			<div className="flex flex-col gap-3 w-full max-w-header-width">
				{zoneList.map((item, i) => (
					<HeaderZoneListItem key={i} {...item} onClick={() => onClickItem(item)} />
				))}
			</div>
		</div>
	);
}

export default HeaderZoneList;
