import { MaterialLikeType } from "@/libs/xrsdk/types";
import createFloorGuide from "@/libs/xrsdk/utils/floorGuide";
import { correctYValues, parseMaterialLike } from "@/libs/xrsdk/utils/threeUtils";
import XrSceneComponent from "@xrsdk/abstracts/XrSceneComponent";
import * as THREE from "three";



export type FloorGuideSceneComponentProps = {
	points: THREE.Vector3[]

	path: {
		width?: number
		materialLike: MaterialLikeType
	}

	indicator?: {
		gap?: number
		materialLike: MaterialLikeType
	} | null
}

// type Outputs = {}&ComponentOutput;

class FloorGuideSceneComponent extends XrSceneComponent<FloorGuideSceneComponentProps>{
	events = {}
	emits = {}
	inputs = {
		path: {
			materialLike: { color: 0xffffff },
		},
		points: [],
		indicator: null,
	} as FloorGuideSceneComponentProps;

	onInit() {
		this.root = new THREE.Object3D();
		this.outputs.objectRoot = this.root;
		this.outputs.collider = this.root;
		const points = this.inputs.points;
		correctYValues(points);

		createFloorGuide({
			path: {
				material: parseMaterialLike(this.inputs.path.materialLike),
				width: this.inputs.path.width
			},
			points: points,
			indicator: this.inputs.indicator ? {
				material: parseMaterialLike(this.inputs.indicator.materialLike),
				gap: this.inputs.indicator.gap
			} : null,
		}).then(floorGuide => {
			this.root.add(floorGuide);
		});
	}

	onDestroy(): void {
		//TODO: remove object from scene
		this.root.parent!.remove(this.root);
	}
}

export default FloorGuideSceneComponent;
