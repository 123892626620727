import GlobalCursorContext from "@/context/globalCursor";
import XrComponent from "@/libs/xrsdk/abstracts/XrComponent";
import { ComponentInteractionType } from "@/libs/xrsdk/enums";
import { XrComponentProps } from "@/libs/xrsdk/types";
import { xyzToVector3 } from "@/libs/xrsdk/utils/threeUtils";
import { IDisposable, MpSdk } from "@matterport/webcomponent";
import TagInfoModal from "./TagModal";
import TagSceneComponent, { TagSceneComponentProps } from "./TagSceneComponent";

const TAG_IMAGES = {
	default: "/img/icons/tag_4x.png",
	play: "/img/icons/play_4x.png",


	defaultEffect: "/img/icons/tagEffect_4x.png",
}


export type TextImageInfoItemType = {
	infoType: "imageText"
	title: string
	subTitle?: string
	image?: string
	infoText?: string
	mapPinText?: string
	chemicalText?: string
	content: string
	limitImageHeight?: boolean
}

export type VideoInfoItemType = {
	infoType: "video"
	title: string
	subTitle?: string
	videoUrl: string
	content: string
}

export type InfoItemType = TextImageInfoItemType | VideoInfoItemType;

export type TagIconType = "default" | "video";

export interface TagComponentItemType {
	position: XYZ
	radius?: number
	tagIconType?: keyof typeof TAG_IMAGES
	tagEffectIconType?: keyof typeof TAG_IMAGES
	infoList: InfoItemType[]
}

export type TagComponentProps = TagComponentItemType & XrComponentProps;

interface State {
	active: boolean
}

class TagComponent extends XrComponent<TagComponentProps, State>{
	static contextType = GlobalCursorContext.ActionContext;
	static readonly componentKey = "xrleader.tag";
	static readonly sceneComponentClass = TagSceneComponent;

	state: State = {
		active: false
	}

	public get static() {
		return (this.constructor as typeof TagComponent)
	}

	private static sceneComponentDisposable: IDisposable | null = null;
	public static async initScene(sdk: MpSdk) {
		if (this.sceneComponentDisposable) return;
		this.sceneComponentDisposable = await sdk.Scene.register(this.componentKey, () => new this.sceneComponentClass());
	}

	constructor(props: TagComponentProps) {
		super(props);
		this.static.initScene(props.sdk);
	}

	private sceneComponent!: MpSdk.Scene.IComponent;
	private scene!: MpSdk.Scene.IObject;

	private async initSceneComponent() {
		if (this.sceneComponent)
			return;

		[this.scene] = await this.props.sdk.Scene.createObjects(1);
		const node = this.scene.addNode();

		this.sceneComponent = node.addComponent(this.static.componentKey, {
			src: TAG_IMAGES[this.props.tagIconType || "default"],
			effectSrc: this.props.tagEffectIconType || TAG_IMAGES["defaultEffect"],
			position: xyzToVector3(this.props.position),
			radius: this.props.radius,
		} as TagSceneComponentProps);
		node.start();

		const clickSpy: MpSdk.Scene.ISceneObjectSpy = {
			path: this.scene.addEventPath(this.sceneComponent, ComponentInteractionType.CLICK),
			onEvent: (eventData) => {
				this.toggleActive();
			}
		}
		this.scene.spyOnEvent(clickSpy);


		const hoverSpy: MpSdk.Scene.ISceneObjectSpy = {
			path: this.scene.addEventPath(this.sceneComponent, ComponentInteractionType.HOVER),
			onEvent: (eventData: MpSdk.Scene.InteractionEvent) => {
				(this.context as any).set(eventData.hover);
			}
		}
		this.scene.spyOnEvent(hoverSpy);
	}

	componentDidMount() {
		this.initSceneComponent();
	}
	componentWillUnmount() {
		this.scene.stop();
		this.static.sceneComponentDisposable?.dispose();
	}

	toggleActive = () => {
		this.setState({ active: !this.state.active });
	}


	render() {
		if (!this.state.active) return null;
		return (
			<TagInfoModal infoList={this.props.infoList} toggleActive={this.toggleActive} />
		)
	}
}

export default TagComponent;
