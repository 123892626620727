import * as THREE from 'three';
import PathGeometry from './three/PathGeometry';

const PATH_STEP_FACTOR = 8;
const PATH_HOVER = 0.01;
const INDICATOR_HOVER = 0.01;
/* 
const pathUVGenerator: THREE.UVGenerator = {
	generateTopUV: function (geometry: THREE.BufferGeometry, vertices: number[], idxA: number, idxB: number, idxC: number) {
		const ax = vertices[idxA * 3];
		const ay = vertices[(idxA * 3) + 1];
		const bx = vertices[idxB * 3];
		const by = vertices[(idxB * 3) + 1];
		const cx = vertices[idxC * 3];
		const cy = vertices[(idxC * 3) + 1];

		return ([
			new THREE.Vector2(ax, ay),
			new THREE.Vector2(bx, by),
			new THREE.Vector2(cx, cy),
		]);
	},
	generateSideWallUV: () => [
		new THREE.Vector2(0, 0),
		new THREE.Vector2(1, 0),
		new THREE.Vector2(1, 1),
		new THREE.Vector2(0, 1),
	]
};
 */


const createPath = (curve: THREE.CatmullRomCurve3, path: { material: THREE.Material, width: number }) => {
	const geometry = new PathGeometry(curve, path.width, PATH_STEP_FACTOR);
	geometry.computeBoundingBox();
	geometry.computeVertexNormals();

	const pathObj = new THREE.Mesh(geometry, path.material);

	pathObj.position.add(new THREE.Vector3(0, PATH_HOVER, 0));
	pathObj.renderOrder = 100;


	return pathObj;
}

const createIndicator = (curve: THREE.CatmullRomCurve3, indicator: { material: THREE.Material, gap: number }, pathWidth: number) => {
	const steps = Math.floor(curve.getLength() / indicator.gap);

	const scale = (indicator.material.userData.scale ?? 1);
	const aspectRatio = (indicator.material.userData.aspectRatio ?? 1);
	const geometry = new THREE.PlaneGeometry(pathWidth * scale, pathWidth * aspectRatio * scale);

	const indicatorsObj = new THREE.Group();

	for (let i = 1; i < steps; i++) {
		const t = i / steps;
		const next_t = t + (Math.min(1, (1 / steps)) * 0.001);

		const position = curve.getPointAt(t);
		const next_position = curve.getPointAt(next_t);

		position.add(new THREE.Vector3(0, PATH_HOVER + INDICATOR_HOVER, 0));
		next_position.add(new THREE.Vector3(0, PATH_HOVER + INDICATOR_HOVER, 0));

		const mesh = new THREE.Mesh(geometry, indicator.material);
		mesh.rotateX(-0.5 * Math.PI);
		mesh.rotateZ(Math.PI);
		mesh.renderOrder = 101;

		const arrow = new THREE.Group();
		arrow.add(mesh);

		arrow.position.copy(position);
		arrow.lookAt(next_position);

		indicatorsObj.add(arrow);
	}

	return indicatorsObj;
};


type CreateFloorGuideParams = {
	points: THREE.Vector3[]
	path: {
		material: THREE.Material
		width?: number
	}
	indicator?: {
		material: THREE.Material
		gap?: number
	} | null
}
const createFloorGuide = async ({ points, path, indicator }: CreateFloorGuideParams) => {
	const floorGuide = new THREE.Group();

	if (points.length < 2) {
		console.error('points length must be greater than 2');
		return floorGuide;
	}
	const curve = new THREE.CatmullRomCurve3(points);
	const pathWidth = path.width || 0.5;

	const pathObj = createPath(curve, {
		material: path.material,
		width: pathWidth
	});
	floorGuide.add(pathObj);

	if (indicator) {
		const indicatorObj = createIndicator(curve, {
			material: indicator.material,
			gap: indicator.gap || 1,
		}, pathWidth);
		floorGuide.add(indicatorObj);
	}

	return floorGuide;
};

export default createFloorGuide;
