import MainApp from "@/pages/MainApp";
import { ZONE_LIST } from "./services/data/zoneList";
import { SPACE_LIST } from "./services/data/spaceList";



const App: React.FC = () => {
	return (
		<MainApp zoneList={ZONE_LIST} spaceList={SPACE_LIST} />
	);
}

export default App;
