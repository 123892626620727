import { MoveToSweepFunctionType } from "@/pages/MainApp";
import ExhibitsListItem from "./ExhibitsListItem";

type ExhibitsListProps = {
	exhibitsList: ExhibitsItemType[]
	onClickMove: MoveToSweepFunctionType
};

const ExhibitsList: React.FC<ExhibitsListProps> = ({ exhibitsList, onClickMove }: ExhibitsListProps) => {
	return (
		<div className="flex flex-col gap-3 w-full">
			{exhibitsList.map((object, i) => (
				<ExhibitsListItem key={object.sweepRotation.sweepId} exhibits={object} onClickMove={onClickMove} />
			))}
		</div>
	);
}

export default ExhibitsList;
