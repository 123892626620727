import { MpSdk } from "@matterport/webcomponent/built-bundle/sdk";
import { ComponentInteractionType } from "../enums";
import { TransformType, parseTransform } from "./threeUtils";

type TransformableParams = {
	scene: MpSdk.Scene.IObject,
	node: MpSdk.Scene.INode,
	component: MpSdk.Scene.IComponent,

	// TODO: control의 transform이 인자로 넘어감-> 원본 오브젝트의 transform을 못가져옴
	onSelected? :(transform:TransformType)=>void
	onMoved? :(transform:TransformType)=>void
};

export class Transformable{
	public static controls:MpSdk.Scene.IComponent = null!;
	private static onMoved:(transform:TransformType)=>void = ()=>{};

	public static async initControls(sdk:MpSdk): Promise<void>{
		if(this.controls != null)
			return;

		const [scene] = await sdk.Scene.createObjects(1);
		const node = scene.addNode();
		const controls = node.addComponent('mp.transformControls') as typeof Transformable.controls;
		node.start();
		
		this.controls = controls;
		// controls!.inputs!.mode = "rotate"
		controls.events[ComponentInteractionType.CLICK] = true;
		
		
		// test
		scene.spyOnEvent({
			path: scene.addEventPath(controls, ComponentInteractionType.DRAG_END),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onEvent: ()=>{Transformable.onMoved(parseTransform((Transformable as any).controls.inputs.selection.obj3D));}
		});

		const CONTROL_MODES = ["translate", "rotate", "scale"];
		scene.spyOnEvent({
			path: scene.addEventPath(controls, ComponentInteractionType.CLICK),
			onEvent: (eventData:{input:{button:number}})=>{
				if(eventData.input.button === 2){
					controls.inputs!.mode = CONTROL_MODES[(CONTROL_MODES.indexOf(controls.inputs!.mode as string)+1)%3];
				}
			}
		});
	}

	constructor({ scene, node, component, onSelected, onMoved }: TransformableParams){
		scene.spyOnEvent({
			path: scene.addEventPath(component, ComponentInteractionType.CLICK),
			onEvent() {
				Transformable.controls.inputs!.selection = node;
				Transformable.onMoved = onMoved ?? (() => { });
				onSelected?.(parseTransform((Transformable as any).controls.inputs.selection.obj3D));
			}
		});
	}
}
