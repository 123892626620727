import Button from '@/components/common/Button';
import PlainModal from '@/components/common/PlainModal';
import { clamp } from 'lodash';
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react';
import { useState } from 'react';
import { InfoItemType } from '../TagComponent';
import TagComponentInfo from './TagComponentInfo';

type TagInfoModalProps = {
	infoList: InfoItemType[]
	toggleActive: () => void
};

const TagInfoModal: React.FC<TagInfoModalProps> = ({ infoList, toggleActive }: TagInfoModalProps) => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const addIndex = (index: number) => {
		setSelectedIndex(clamp(selectedIndex + index, 0, infoList.length - 1));
	}


	return (
		<PlainModal closeModal={toggleActive} className="flex rounded-2xl max-h-full max-w-header-width" innerClassName='overflow-y-auto w-full'>
			<TagComponentInfo {...infoList[selectedIndex]} key={selectedIndex} />


			{infoList.length > 1 &&
				<div className="flex items-center justify-between mt-10">
					{selectedIndex > 0 ?
						<Button variants={{ style: "icon" }} onClick={() => addIndex(-1)}>
							<ArrowLeftIcon className="text-2xl" />
						</Button>
						: <div className='w-8' />
					}
					<div className="text-gray-500">{selectedIndex + 1} / {infoList.length}</div>
					{selectedIndex < infoList.length - 1 ?
						<Button variants={{ style: "icon" }} onClick={() => addIndex(1)}>
							<ArrowRightIcon className="text-2xl" />
						</Button>
						:
						<div className='w-8' />
					}

				</div>
			}
		</PlainModal>
	);
}

export default TagInfoModal;
