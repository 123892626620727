
type ExhibitsTabPanelProps = {
	//
} & React.PropsWithChildren;

const ExhibitsTabPanel: React.FC<ExhibitsTabPanelProps> = ({ children }: ExhibitsTabPanelProps) => {
	return (
		<div role="tabpanel">
			{children}
		</div>
	);
}

export default ExhibitsTabPanel;
