import XrSceneComponent from "@/libs/xrsdk/abstracts/XrSceneComponent";
import { MpSdk } from "@matterport/webcomponent";
import * as THREE from "three";
import { ComponentInteractionType } from "../../enums";
import { createCube } from "../threeUtils";

type CubeSceneComponentProps = {
	position?: THREE.Vector3;
	scale?: THREE.Vector3;
	color?: number;
	opacity?: number;
}
class CubeSceneComponent extends XrSceneComponent<CubeSceneComponentProps> {
	events = {
		[ComponentInteractionType.CLICK]: true,
	}
	emits = {

	}

	inputs!: CubeSceneComponentProps;
	constructor() {
		super();

		this.inputs = {
			position: new THREE.Vector3(0, 0, 0),
			scale: new THREE.Vector3(1, 1, 1),
			color: 0xff0000,
			opacity: 0.8,
			...this.inputs,
		};
	}

	onInit() {
		const cube = createCube({ scale: this.inputs.scale, color: this.inputs.color, opacity: this.inputs.opacity });
		cube.position.copy(this.inputs.position!);
		this.root = cube;

		this.outputs.objectRoot = this.root;
		this.outputs.collider = cube;
	}
	onEvent(eventType: ComponentInteractionType, eventData: MpSdk.Scene.InteractionEvent): void {
	}

	onInputsUpdated(previousInputs: this["inputs"]): void {
	}

	onDestroy(): void {
	}

}

export default CubeSceneComponent;
