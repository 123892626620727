export const ZONE_LIST: ZoneListItemType[] = [
	{
		title: "본관동 제1전시실",
		content: "동양의 자연관, 생명시작의 씨앗 그리고 우리지역의 자생식물은 물론 실물을 만져볼수 있는 공간과 동물들의 집짓기 등을 볼수 있으며 사라지는 숲을 통해 자연보호 실천을 할 수 있는 자연학습 공간으로 구성되어 있습니다.",
		// floorId: "tsmq1wak12rhgn0mawksxcwcd",
		defaultSweepRotation: { "sweepId": "x3wr9yr3u6bhkw81a2cn3bkcc", "rotation": { "x": -2.7, "y": -177.65 } },
		boundary: [
			{
				"start": { "x": -26.861558114822735, "y": -1.6297051641581755, "z": -13.497332632857763 },
				"end": { "x": 49.01954710955365, "y": 5.5, "z": 37.61638556375836 }
			}
		]
	},
	{
		title: "본관동 제2전시실",
		content: "본관동 제2전시실은 인간과 자연의 만남을 주제로한 생각의 숲과 다양한 유형의 광물 및 화석, 숲에서 나는 소리를 들을 수 있는 숲 체험관으로 구성되었습니다.",
		// floorId: "pd0i1c4yq9w0y2zsb07ed23sb",
		defaultSweepRotation: { "sweepId": "0u3xrb73pgxxa55zqztdf2eha", "rotation": { "x": 2.6, "y": -51.65 } },
		boundary: [
			{
				"start": { "x": 14.930727257867668, "y": 5.532726899586617, "z": -10.436862765875869 },
				"end": { "x": 45.39932560088028, "y": 12.364730623701586, "z": 25.265085002078184 }
			}
		]
	},
	{
		title: "본관동 제3전시실",
		content: "새를 주제로 실물박제 전시 및 '디지털 숲 체험', 'AR색칠놀이', '트릭아트 벽화체험' 등 다양한 체험요소를 접목해 새와 산림의 중요성을 배울 수 있는 공간으로 구성되어있습니다.",
		// floorId: "h0ysdfsrzyt6errbx1x8g92wc",
		defaultSweepRotation: { "sweepId": "c02zk0mgu7h7mt4315d0aiuab", "rotation": { "x": -1.64, "y": 88.67 } },
		boundary: [
			{
				"start": { "x": -14.895170913664035, "y": 5.953758333936942, "z": -4.534357519066738 },
				"end": { "x": 9.764128952053596, "y": 12.882120090509925, "z": 23.24876324429137 }
			}
		]
	}
]

