import { InfoItemType } from "../../TagComponent";
import TagComponentImageTextInfo from "./TagComponentImageTextInfo";
import TagComponentImageVideo from "./TagComponentImageVideo";

type TagComponentInfoProps = InfoItemType;

const TagComponentInfo: React.FC<TagComponentInfoProps> = ({ ...props }: TagComponentInfoProps) => {
	return (
		props.infoType === "imageText" ? (
			<TagComponentImageTextInfo {...props} />
		) : props.infoType === "video" ? (
			<TagComponentImageVideo {...props} />
		) : null
	);
}

export default TagComponentInfo;
