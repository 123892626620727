import { debugBoundary, debugCameraInfo, debugCurrentSweepInfo, DebugFunctionType, debugPointer, debugRoom, debugSweepXYInfo, debugTagPosition, debugUserInput } from "@/utils/mpUtils";
import { MpSdk } from "@matterport/webcomponent";
import { useState } from "react";

type DebugPanelProps = {
	sdk: MpSdk | null
};

const debugList: Record<string, DebugFunctionType> = {
	"pointer": debugPointer,
	"camera": debugCameraInfo,
	"sweep": debugCurrentSweepInfo,
	"sweepXY": debugSweepXYInfo,
	"room": debugRoom,
	"userInput": debugUserInput,
	"debugTagPosition": debugTagPosition,
	"debugBoundary": debugBoundary,
};

const DebugPanel: React.FC<DebugPanelProps> = ({ sdk }: DebugPanelProps) => {

	const [debugDestroies, setDebugDestroies] = useState<Record<string, (() => void) | undefined>>({});

	const toggleDebug = (key: string) => {
		if (!sdk) return;
		const debugDestroy = debugDestroies[key];
		if (debugDestroy) {
			debugDestroy();
			setDebugDestroies({ ...debugDestroies, [key]: undefined });
		} else {
			debugList[key](sdk).then((destroy) => {
				setDebugDestroies({ ...debugDestroies, [key]: destroy });
			});
		}
	}

	return (
		<div className="fixed flex flex-col z-50 bg-white/50 right-2 top-2 p-1 px-3 rounded opacity-50">
			{debugList && Object.keys(debugList).map((key) => (
				<label key={key} className="flex gap-2">
					<input type="checkbox" checked={debugDestroies[key] !== undefined} onChange={() => toggleDebug(key)} />
					<span>{key}</span>
				</label>
			))}
		</div>
	);
}

export default DebugPanel;
