import { cn } from "@/utils/cn";
import { cva, VariantProps } from 'class-variance-authority';

export const PlainVariants = cva(
	``,
	{
		variants: {
			variant: {
				primary: `
					bg-white rounded-xl p-5 
				`,
			},
			shadow: {
				default: "shadow-lg",
				sm: "shadow-md",
				none: "",
			}
		},
		defaultVariants: {
			variant: 'primary',
			shadow: 'default',
		},
	},
);

type PlainProps = {
	variants?: VariantProps<typeof PlainVariants>;
} & React.HTMLAttributes<HTMLDivElement>;

const Plain: React.FC<PlainProps> = ({ variants, className, children, ...props }) => {
	return (
		<div {...props} className={cn(PlainVariants(variants), className)}>{children}</div>
	);
};

export default Plain;
