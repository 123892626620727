import MainHeader from "@/components/layout/MainHeader";
import { XrComponentOptionType, XRManagerInitialData } from "@/libs/xrsdk/types";
import UserXrManager from "@/services/matterport/UserXrManager";
import { IS_DEV, MP_APPLICATION_KEY, PUBLIC_PATH } from "@/utils/config";
import { MpSdk } from "@matterport/webcomponent";
import { getSdkContext } from "@xrsdk/utils/threeUtils";
import { useState } from "react";

import Intro from "@/components/layout/Intro";
import { MainAppComponentOptionList } from "@/components/xr/xr.types";
import GlobalCursorContext from "@/context/globalCursor";
import DebugPanel from "@/libs/xrsdk/utils/debug/DebugPanel";
import { FLOOR_GUIDE_LIST } from "@/services/data/floorGuideList";
import { TAG_LIST } from "@/services/data/tagList";
import "styles/app.css";




const initialData: XRManagerInitialData = {
	viewerOptions: {
		customOptions: {
			disableMeasurement: true,
			disableTagDock: true,
			disableTagNav: true,
			disableTagShare: true,
			hideBottomText: true,
			hideFullscreen: false,
			hidePoweredBy: true,
		},
		params: {
			"m": "gkiN2dVWcSG",
			"application-key": MP_APPLICATION_KEY,
			"asset-base": PUBLIC_PATH + "/bundle",
			"play": '1',
			"qs": '1',
			"search": '0',
			"brand": '0',
			"tour": '0',
			"mls": "2",
			"vr": '0',
			"log": '0',
			// "mt": '0', // 메타태그
			// "dh": '0', // 돌하우스
			// "fp": '0', // 평면도
		}
	}
}

const components: XrComponentOptionType<MainAppComponentOptionList>[] = [
	...TAG_LIST.map<XrComponentOptionType<MainAppComponentOptionList>>((props) => ({
		key: "xrleader.tag",
		props: props,
	})),
	...FLOOR_GUIDE_LIST.map<XrComponentOptionType<MainAppComponentOptionList>>((props) => ({
		key: "xrleader.floorGuide",
		props: props,
	})),
];

export type MoveToSweepFunctionType = (sweepRotation: SweepRotationType) => Promise<string | undefined>


type MainPageProps = {
	zoneList: ZoneListItemType[]
	spaceList: SpaceListItemType[]
}


const MainApp: React.FC<MainPageProps> = ({ zoneList, spaceList }: MainPageProps) => {
	const [sdk, setSdk] = useState<MpSdk | null>(null);

	// TODO: 렌더링 횟수 최적화
	// const sweepDict = useSweepDict({ sdk });

	const onSdkConnected = async (_sdk: MpSdk) => {
		(window as any).sdk = _sdk;
		(window as any).getSdkContext = getSdkContext;
		setSdk(_sdk);
	};
	/*
	const moveToFloor = async (floorId: string) => {
		if (!sdk) return;
		const floor: FloorListItemType | null = findFloorByFloorId(floorList, floorId);

		if (!floor) return;
		if (floor.defaultPosition)
			sdk.Sweep.moveTo(floor.defaultPosition.sweepId, { rotation: floor.defaultPosition.rotation });
		else {
			const floorIndex = (await sdk.Floor.Conversion.createIdMap())[floorId];
			sdk.Floor.moveTo(Number(floorIndex));
		}
	}
	*/
	const moveToSweep: MoveToSweepFunctionType = async ({ sweepId, rotation }) => {
		if (!sdk) return;
		return await sdk.Sweep.moveTo(sweepId, rotation ? { rotation } : {});
	}

	return (
		<>
			<Intro isLoading={sdk == null} />
			{IS_DEV && <DebugPanel sdk={sdk} />}
			<MainHeader sdk={sdk} zoneList={zoneList} spaceList={spaceList} moveToSweep={moveToSweep} />

			<GlobalCursorContext.Provider>
				<UserXrManager
					onSdkConnected={onSdkConnected}
					initialData={initialData}
					components={components}
				/>
			</GlobalCursorContext.Provider>
		</>
	)
}

export default MainApp;
