import { Dict } from "../types";

export const updateUrlParams = (params: Dict, url = location.href, makeHistory = false): void => {
	const newUrlObj = new URL(url);
	for (const key in params)
		newUrlObj.searchParams.set(key, params[key]);

	if (makeHistory)
		history.pushState({}, "", newUrlObj);
	else
		history.replaceState({}, "", newUrlObj);
}

export const getUrlParams = (url = location.href): Dict => {
	const urlObj = new URL(url);
	const params: Dict<string> = {};
	for (const [key, value] of [...urlObj.searchParams])
		params[key] = value;
	return params;
}

export const removeUrlParams = (keys: string[], url = location.href, makeHistory = false): void => {
	const urlObj = new URL(url);
	for (const key of keys)
		urlObj.searchParams.delete(key);

	if (makeHistory)
		history.pushState({}, "", urlObj);
	else
		history.replaceState({}, "", urlObj);
}
