import { cn } from "@/utils/cn";

type PlainModalBgProps = {
	closeModal?: () => void
} & React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

const PlainModalBg: React.FC<PlainModalBgProps> = ({ closeModal, className, children, onClick: _onClick, ...props }: PlainModalBgProps) => {
	const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
		closeModal?.();
		_onClick?.(e);
	};
	return (
		<div className={cn("absolute inset-0 bg-black/30", className)} onClick={onClick} {...props}>
			{children}
		</div>
	);
}

export default PlainModalBg;
