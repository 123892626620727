import { cn } from "@/utils/cn";

type ModalBgProps = {
	closeBg?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const ModalBg: React.FC<ModalBgProps> = ({ closeBg, className, ...props }: ModalBgProps) => {
	return (
		<div className={cn("absolute inset-0 bg-black/40 z-10", className)} onClick={closeBg} {...props}></div>
	);
}

export default ModalBg;
