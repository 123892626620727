import { PlainVariants } from "@/components/common/Plain";
import { cn } from "@/utils/cn";
import { ArrowRightIcon } from "lucide-react";

type HeaderZoneListItemProps = {
	onClick?: () => void
} & ZoneListItemType

const HeaderZoneListItem: React.FC<HeaderZoneListItemProps> = ({ title, content, onClick }: HeaderZoneListItemProps) => {
	return (
		<button className={cn(PlainVariants(), "text-start group transition hover:shadow-md")} onClick={onClick}>
			<div className="flex gap-2 items-center justify-between">
				<span className="text-lg font-semibold underline underline-offset-4">{title}</span>
				<ArrowRightIcon className="text-lg text-gray-600 group-hover:text-black group-hover:translate-x-1 transition" />
			</div>
			<p className="text-gray-600 mt-3">
				{content}
			</p>
		</button>
	);
}

export default HeaderZoneListItem;
