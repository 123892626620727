import { ComponentInteractionType } from "@/libs/xrsdk/enums";
import { easeInOutQuad, loadImageTexture, interpolate } from "@/libs/xrsdk/utils/threeUtils";
import { MpSdk } from "@matterport/webcomponent";
import XrSceneComponent from "@xrsdk/abstracts/XrSceneComponent";
import * as THREE from "three";


const RADIUS_RATIO = 0.2;

export type TagSceneComponentProps = {
	src: string
	effectSrc?: string
	position: THREE.Vector3
	radius?: number
}

// type Outputs = {}&ComponentOutput;

class TagSceneComponent extends XrSceneComponent<TagSceneComponentProps> {
	events = {
		[ComponentInteractionType.CLICK]: true,
		[ComponentInteractionType.HOVER]: true,
	}
	emits = {

	}

	inputs: TagSceneComponentProps = {
		src: "",
		effectSrc: "",
		position: new THREE.Vector3(),
		radius: undefined,
	};

	private cameraPos = new THREE.Vector3();
	private isHovered = false;

	private tagWrap: THREE.Object3D = new THREE.Object3D();
	// private tagObject: THREE.Object3D = new THREE.Object3D();
	private tagSprite: THREE.Sprite = new THREE.Sprite();
	private tagEffectObject: THREE.Sprite = new THREE.Sprite();


	onInit() {
		this.root = new THREE.Object3D();
		const radius = (this.inputs.radius || 1) * RADIUS_RATIO;

		this.root.position.copy(this.inputs.position);
		this.cameraPos = this.context.camera.parent!.position;

		this.tagWrap.position.z = radius;
		this.root.add(this.tagWrap);

		const tagObject = new THREE.Object3D();
		tagObject.scale.set(radius, radius, 1);
		this.tagWrap.add(tagObject);

		const texture = loadImageTexture(this.inputs.src);
		const tagMaterial = new THREE.SpriteMaterial({
			map: texture,
			transparent: true,
		});
		this.tagSprite = new THREE.Sprite(tagMaterial);
		tagObject.add(this.tagSprite);

		const colliderObj = new THREE.Object3D();
		const collider = new THREE.Mesh(new THREE.CircleGeometry(radius, 32), new THREE.MeshBasicMaterial({ visible: false }));
		colliderObj.add(collider);
		this.tagWrap.add(colliderObj);


		if (this.inputs.effectSrc) {
			const effectTexture = loadImageTexture(this.inputs.effectSrc);
			const tagEffectMaterial = new THREE.SpriteMaterial({
				map: effectTexture,
				transparent: true,
				depthWrite: false,
			});
			this.tagEffectObject = new THREE.Sprite(tagEffectMaterial);
			this.tagEffectObject.position.z = -0.01;
			tagObject.add(this.tagEffectObject);
		}


		this.outputs.objectRoot = this.root;

		this.outputs.collider = colliderObj;
	}

	private effectProgress = 0;
	private effectSpeed = 0.01;

	animateEffect(isStopped = false) {
		if (isStopped) {
			this.tagEffectObject.material.opacity = 0;
			return;
		}
		this.effectProgress += this.effectSpeed;
		if (this.effectProgress > 1)
			this.effectProgress = 0;

		const currentProgress = easeInOutQuad(this.effectProgress);

		const scale = 1 + currentProgress * 0.6;
		const opacity = 1 - currentProgress;
		this.tagEffectObject.scale.set(scale, scale, 1);
		this.tagEffectObject.material.opacity = opacity;
	}


	onTick() {
		this.root.lookAt(this.cameraPos);

		const dist = this.root.position.distanceTo(this.cameraPos);

		const scale = interpolate(dist, { xStart: 3, xEnd: 10, yStart: 1, yEnd: 3 });
		this.root.scale.set(scale, scale, 1);


		// TODO
		// const opacity = interpolate(dist, { xStart: 8, xEnd: 15, yStart: 1, yEnd: 0.5 }) * (this.isHovered ? 0.9 : 1);
		// this.tagObject.material.opacity = opacity;

		this.animateEffect(dist > 10);
	}


	onEvent(eventType: ComponentInteractionType, eventData: MpSdk.Scene.InteractionEvent): void {
		if (eventType == ComponentInteractionType.HOVER) {
			this.isHovered = eventData.hover || false;
			this.tagSprite.material.opacity = this.isHovered ? 1.5 : 1;
		}
	}

	onInputsUpdated(previousInputs: this["inputs"]): void {
		if (previousInputs.position != this.inputs.position) {
			this.root.position.copy(this.inputs.position);
		}
	}

	onDestroy(): void {
		this.root.parent!.remove(this.root);
	}
}

export default TagSceneComponent;
