import ModalBg from "@/components/common/ModalBg";
import ExhibitsModal from "@/components/ExhibitsModal";
import useCurrentSweep from "@/hooks/mp/useCurrentSweep";
import useCurrentZone from "@/hooks/mp/useCurrentZone";
import { addCube, getSdkContext, startEndToCenterScale, xyzToVector3 } from "@/libs/xrsdk/utils/threeUtils";
import { MoveToSweepFunctionType } from "@/pages/MainApp";
import { MpSdk } from "@matterport/webcomponent";
import { ChevronDownIcon, LayoutGridIcon, Loader2Icon, MapPinIcon } from "lucide-react";
import { useEffect, useState } from "react";
import Button from "../../common/Button";
import Plain from "../../common/Plain";
import HeaderZoneList from "./HeaderZoneList";






interface MainHeaderProps {
	sdk: MpSdk | null;
	zoneList: ZoneListItemType[]
	spaceList: SpaceListItemType[]
	// currentZone: ZoneListItemType | null
	// moveToFloor: (floorId: string) => Promise<void>
	moveToSweep: MoveToSweepFunctionType
}

const MainHeader: React.FC<MainHeaderProps> = ({ sdk, zoneList, spaceList, moveToSweep }) => {
	const currentSweep = useCurrentSweep({ sdk });
	const currentZone = useCurrentZone({ currentSweep, zoneList });

	// zone 영역 비주얼 확인용 코드
	/*
	useEffect(() => {
		(async () => {
			if (!sdk) return;
			const scene = (await getSdkContext(sdk)).scene

			zoneList.forEach(async zone => {
				if (!zone.boundary) return;
				for (const boundary of zone.boundary) {
					const { start, end } = boundary;
					const [center, scale] = startEndToCenterScale(xyzToVector3(start), xyzToVector3(end));
					addCube({ scene, position: center, scale: scale });
				}
			});
		})();
	}, [sdk]);
	*/

	const [isHeaderZoneListOpen, setIsHeaderZoneListOpen] = useState(false);
	const toggleHeaderZoneList = () => setIsHeaderZoneListOpen(prev => !prev);

	const [isExhibitsModalOpen, setIsExhibitsModalOpen] = useState(false);
	const toggleExhibitsModal = () => setIsExhibitsModalOpen(prev => !prev);

	const onClickZoneItem = (item: ZoneListItemType) => {
		if (!item.defaultSweepRotation)
			return;
		moveToSweep(item.defaultSweepRotation);
		// if (item.floorId === undefined) return;
		// moveToFloor(item.floorId);
		toggleHeaderZoneList();
	}

	return (
		<>
			<header className="absolute w-full flex flex-col pointer-events-none z-20 pt-5 px-3 items-center [&>*]:pointer-events-auto max-h-full gap-4">
				<div className="w-full max-w-header-width ">
					<Plain className="flex justify-between items-center h-14 py-1">
						<button className="flex gap-2 items-center text-gray-600 group" onClick={toggleHeaderZoneList} aria-label="장소 목록 보기/숨기기">
							<MapPinIcon className="text-xl" />
							<div className="flex gap-1 items-center group-hover:text-gray-900 group-active:text-gray-500 transition">
								{currentZone ?
									<>
										<span className="text-lg font-semibold">
											{currentZone.title}
										</span>
										<ChevronDownIcon className={`${isHeaderZoneListOpen ? "rotate-180" : ""} transition-transform`} />
									</>
									: <Loader2Icon className="animate-spin text-gray-600 text-xl" />
								}
							</div>
						</button>
						<div>
							<Button className="flex font-semibold items-center gap-1.5" onClick={toggleExhibitsModal}>
								<LayoutGridIcon className="text-lg stroke-[2px]" />
								<span>전시 목록</span>
							</Button>
						</div>
					</Plain>
				</div>

				{isHeaderZoneListOpen &&
					<HeaderZoneList zoneList={zoneList} onClickItem={onClickZoneItem} />
				}
			</header>

			{isHeaderZoneListOpen && <ModalBg closeBg={toggleHeaderZoneList} />}
			{isExhibitsModalOpen && <ExhibitsModal toggleModal={toggleExhibitsModal} spaceList={spaceList} moveToSweep={moveToSweep} className="z-30" />}
		</>
	);
}

export default MainHeader;
