import XrComponent from "@/libs/xrsdk/abstracts/XrComponent";
import { XrComponentProps } from "@/libs/xrsdk/types";
import { findRoutes } from "@/libs/xrsdk/utils/mpUtils";
import { IDisposable, MpSdk } from "@matterport/webcomponent";
import FloorGuideSceneComponent, { FloorGuideSceneComponentProps } from "./FloorGuideSceneComponent";


export interface FloorGuideComponentItemType {
	sweepIds: string[]
	pathWidth?: number
	excludedSweepIds?: string[]
}

export type FloorGuideComponentProps = FloorGuideComponentItemType & XrComponentProps;

type State = {
	//
}

class FloorGuideComponent extends XrComponent<FloorGuideComponentProps, State>{
	static readonly componentKey = "xrleader.floorGuide";
	static readonly sceneComponentClass = FloorGuideSceneComponent;


	public get static() {
		return (this.constructor as typeof FloorGuideComponent)
	}

	private static sceneComponentDisposable: IDisposable | null = null;
	public static async initScene(sdk: MpSdk) {
		if (this.sceneComponentDisposable) return;
		this.sceneComponentDisposable = await sdk.Scene.register(this.componentKey, () => new this.sceneComponentClass());
	}

	constructor(props: FloorGuideComponentProps) {
		super(props);
		this.static.initScene(props.sdk);
	}

	private sceneComponent!: MpSdk.Scene.IComponent;
	private scene!: MpSdk.Scene.IObject;

	private async initSceneComponent() {
		if (this.sceneComponent)
			return;

		[this.scene] = await this.props.sdk.Scene.createObjects(1);
		const node = this.scene.addNode();


		const points = await findRoutes({ sdk: this.props.sdk, sweepIds: this.props.sweepIds, excludedSweepIds: this.props.excludedSweepIds });

		this.sceneComponent = node.addComponent(this.static.componentKey, {
			points: points,
			path: {
				materialLike: {
					imageUrl: "/img/floorGuide/path.png",
					textureOptions: {
						color: 0x864501,
						transparent: true,
						depthWrite: false,
						opacity: 0.2
					}
				},
				width: this.props.pathWidth
			},
			indicator: {
				materialLike: {
					imageUrl: "/img/floorGuide/arrow.png",
					textureOptions: {
						transparent: true,
						alphaTest: 0.001,
					},
					userData: {
						scale: 0.5
					}
				},
				gap: 1
			}

		} as FloorGuideSceneComponentProps);


		node.start();
	}

	componentDidMount() { 
		this.initSceneComponent();
	}
	componentWillUnmount() {
		this.scene.stop();
		this.static.sceneComponentDisposable?.dispose();
	}

	render() {
		return null;
	}
}

export default FloorGuideComponent;
