import { VideoInfoItemType } from "../../TagComponent";

type TagComponentImageVideoProps = {
	//	
} & VideoInfoItemType

const TagComponentImageVideo: React.FC<TagComponentImageVideoProps> = ({ content, subTitle, title, videoUrl }: TagComponentImageVideoProps) => {
	return (
		<div className="flex flex-col gap-5 ">
			<div className="text-center">
				<div className="text-xl font-semibold">{title}</div>
				{subTitle &&
					<div className="text-sm font-light text-neutral-400">{subTitle}</div>
				}
			</div>

			<div className="flex justify-center">
				<video controls playsInline autoPlay className="w-full rounded-2xl max-h-96 object-cover" src={videoUrl} />
			</div>

			<p className="font-light break-keep">
				{content}
			</p>
		</div>
	);
}

export default TagComponentImageVideo;
