import { cn } from "@/utils/cn";
import { cva, VariantProps } from 'class-variance-authority';

export const ButtonVariants = cva(
	``,
	{
		variants: {
			variant: {
				primary: `
					text-nowrap
					rounded bg-primary text-primary-foreground px-3 h-9 shadow-lg transition
					hover:bg-primary/90 hover:shadow-md
				`,
			},
			style: {
				default: ``,
				icon: `flex justify-center items-center w-9 p-0`,
			},
		},
		defaultVariants: {
			variant: 'primary',
			style: 'default',
		},
	},
);

type ButtonProps = {
	variants?: VariantProps<typeof ButtonVariants>;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ variants, style, className, children, ...props }) => {
	return (
		<button {...props} className={cn(ButtonVariants(variants), className)}>{children}</button>
	);
};

export default Button;
