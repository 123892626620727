// Observer 인터페이스 정의
// interface Observer<T> {
// 	update: (data: T) => void;
// }

/**
 * Represents an observable object that can be subscribed to by observers.
 * @template T The type of data that the observable emits.
 * 
 * @class Observable
 * @example
 * const is_done = new Observable<boolean>(false);
 * 
 * console.info("작업중");
 * is_done.waitUntil(t => t === true).then(() => {
 *   console.info("작업완료");
 * });
 * 
 * // 3초 후 작업 완료로 설정
 * setTimeout(() => {
 *   is_done.value = true;
 * }, 3000);
 */
/**
 * A class representing an observable value that can be subscribed to and notified of changes.
 * @template T The type of the value being observed.
 */
export class Observable<T> {
	/**
	 * An array of callback functions to be called when the value changes.
	 */
	private observers: Array<(data: T) => void> = [];

	/**
	 * The current value being observed.
	 */
	private _value: T;

	/**
	 * Creates a new Observable instance with an initial value.
	 * @param initialValue The initial value to be observed.
	 */
	constructor(initialValue: T) {
		this._value = initialValue;
	}

	/**
	 * Adds a callback function to the observers array.
	 * @param callback The callback function to be called when the value changes.
	 * @returns A function to remove the callback from the observers array.
	 */
	public subscribe(callback: (data: T) => void): () => void {
		this.observers.push(callback);
		return () => this.unsubscribe(callback);
	}

	/**
	 * Removes a callback function from the observers array.
	 * @param callback The callback function to be removed from the observers array.
	 */
	private unsubscribe(callback: (data: T) => void): void {
		const index = this.observers.indexOf(callback);
		if (index !== -1) {
			this.observers.splice(index, 1);
		}
	}

	/**
	 * Notifies all observers of a change in the value.
	 */
	private notify(): void {
		// 20231211 foreach중 unsubscribe되는 경우 배열이 밀리는 문제가 있어서 복사해서 사용
		[...this.observers].forEach((callback) => callback(this._value));
	}

	/**
	 * Sets the value being observed and notifies all observers of the change.
	 * @param val The new value to be observed.
	 */
	public set value(val: T) {
		this._value = val;
		this.notify();
	}

	/**
	 * Gets the current value being observed.
	 * @returns The current value being observed.
	 */
	public get value(): T {
		return this._value;
	}

	/**
	 * Waits until a certain condition is met before resolving the promise.
	 * @param predicate The condition to be met before resolving the promise.
	 * @returns A promise that resolves when the condition is met.
	 */
	public async waitUntil(predicate: (data: T) => boolean): Promise<void> {
		if (predicate(this._value)) {
			return Promise.resolve();
		}

		return new Promise<void>((resolve) => {
			const callback = (data: T) => {
				if (predicate(data)) {
					this.unsubscribe(callback);
					resolve();
				}
			};

			this.subscribe(callback);
		});
	}
}
