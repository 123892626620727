import { TagComponentItemType } from "@/components/xr/Tag/TagComponent";

export const TAG_LIST: TagComponentItemType[] = [
	{
		position: { x: 43.48092321243004, y: 3.3368364593938087, z: 1.3348913013812052 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "스라소니",
				"subTitle": "Lynx lynx",
				"image": "/img/exhibits/floor_2/1-1.jpg",
				"infoText": "포유류",
				"content": "추운 지역에 잘 적응한 산림성 동물로 야행성이며,  다른 고양이과 동물들과 달리 추적해 먹이를 잡아먹는  경우가 많다. 북한 함경북도와 자강도 일대에 매우 적은 개체가 생존하는 것으로 추측된다. 모피 때문에 남획되어 국내에서는 절멸된 것으로 추정된다."
			},
			{
				"infoType": "imageText",
				"title": "수달",
				"subTitle": "Lutra lutra",
				"image": "/img/exhibits/floor_2/1-2.jpg",
				"infoText": "포유류",
				"content": "몸은 수중 생활을 하기에 알맞게 발달되었으며 네 발바닥에 두터운 물갈퀴가 있다. 과거 전국 하천에 흔하게 분포하였으나, 수질오염과 모피를 위한 남휙으로 개체수가 줄었다."
			},
			{
				"infoType": "imageText",
				"title": "삵",
				"subTitle": "Prionailurus bengalensis",
				"image": "/img/exhibits/floor_2/1-3.jpg",
				"infoText": "포유류",
				"content": "살쾡이라고도 불리며, 고양이보다 크고, 얼굴 세로선과 털의 부정확한 반점이 특징이다. 식성은 설치류, 조류 등을 사냥하는 기회적 포식자이다. 산림이나 들판, 민가 주변 식생지대 등에 산다"
			},
		],
	},

	{
		position: { x: 43.88583487399995, y: 3.3233595719977322, z: 2.464725562612418 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "노랑부리백로",
				"subTitle": "Egretla eulophotes ",
				"image": "/img/exhibits/floor_2/2-1.jpg",
				"infoText": "조류",
				"content": "몸은 흰색이고, 갯벌, 논 등에서 생활하며 갑각류 등을 먹는다. 여름철새로 우리나라 서해안 및 러시아, 중국에서 번식하며, 비번식기에는 말레이시아, 필리핀 등으로 이동한다. "
			},
			{
				"infoType": "imageText",
				"title": "매",
				"subTitle": "Grus japonensis",
				"image": "/img/exhibits/floor_2/2-2.jpg",
				"infoText": "조류",
				"content": "우리나라의 하구, 호수, 농경지 인근에서 주로 관찰되는 텃새이다.  몸 윗면은 청회색이고, 뺨에 검은색 무늬가 특징이다. 1950~60년대 전 세계적인 DDT의 사용으로 번식에 큰 타격을 입은 대표적인 맹금류이다."
			},
			{
				"infoType": "imageText",
				"title": "검독수리",
				"subTitle": "Aquila chrysaetos",
				"image": "/img/exhibits/floor_2/2-3.jpg",
				"infoText": "조류",
				"content": "암갈색 대형 수리로 하천, 평야 등에서 월동하는 겨울철새이다. 토끼, 다람쥐, 오리 등을 먹는다. 과거에는 국내에 번식하였으나 현재는 확인되지 않고 있다. 하지만 국제적으로는 안정된 수가 유지되고 있어 급격한 멸종의 위협은 적은 것으로 평가된다."
			},
			{
				"infoType": "imageText",
				"title": "고니",
				"subTitle": "Cygnus columbianus",
				"image": "/img/exhibits/floor_2/2-4.jpg",
				"infoText": "조류",
				"content": "몸은 전체적으로 흰색, 다리는 검은색이다.  부리는 전체적으로 검은색이지만 기부 쪽은 노란색이다. 10~3월까지 머무는 겨울철새로, 호수, 강 하구, 해안, 습지 등지에서 월동한다."
			},
		],
	},
	{
		position: { x: 44.38641738430863, y: 3.369057378543571, z: 3.8599268248586776 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "가시고시",
				"subTitle": "Pungitius sinensis",
				"image": "/img/exhibits/floor_2/3-1.jpg",
				"infoText": "어류",
				"content": "동해로 유입되는 하천 중ᆞ하류로 물이 맑고 수초가 많은 곳에 서식한다. 4~5월에 산란이 이루어지는 것으로 추정하나 정확한 생태는 알려진 바가 없으며, 수컷이 산란장을 지키는 것으로 알려졌다."
			},
			{
				"infoType": "imageText",
				"title": "감돌고기",
				"subTitle": "Pseudopungtungia nigra Mori",
				"image": "/img/exhibits/floor_2/3-2.jpg",
				"infoText": "어류",
				"content": "우리나라 고유종이다. 맑은 물이 흐르는 자갈 바닥에 서식하며 깔따구 유충 등의 수서 곤충을 주로 먹고 산다. 충청남ᆞ북도 금강 일대와 전라북도 만경강 등에 분포한다."
			},
			{
				"infoType": "imageText",
				"title": "얼룩새코미꾸리",
				"subTitle": "Koreocobitis naktongensis",
				"image": "/img/exhibits/floor_2/3-3.jpg",
				"infoText": "어류",
				"content": "낙동가 수계에만 서식하는 우리나라 고유종이다. 저서성 소형어류로 몸통에 불규칙한 얼룩무늬가 뚜렷하다. 하천 중상류의 자갈 바닥에 살며, 주로 수서 곤충을 먹는다."
			},
		],
	},
	{
		position: { x: 44.62590790146215, y: 3.3501438246167266, z: 4.528261468351409 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "무척추동물 - 거제외줄달팽이",
				"subTitle": "Satsuma myomphala ",
				"image": "/img/exhibits/floor_2/4-1.jpg",
				"infoText": "무척추동물",
				"content": "해안가 도서지역의 보존된 숲속에서 서식한다. 경상남도와 일본에 분포하는 동북아시아 고유종이다.  서식지 개발 등으로 멸종위기에 처했다."
			},
			{
				"infoType": "imageText",
				"title": "무척추동물 - 둔한진총산호",
				"subTitle": "Euplexaura crassa K_kentha",
				"image": "/img/exhibits/floor_2/4-2.jpg",
				"infoText": "무척추동물",
				"content": "해류 흐름이 빠른 지역에 서식하며 암반에 부착하여 부채모양으로 성장한다.  우리나라 남해안과 제주도 해역에 분포한다."
			},
		],
	},
	{
		position: { x: 44.90507753005308, y: 3.2318746413449517, z: 5.309135146869213 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "표범장지뱀",
				"subTitle": "Eremias argus",
				"image": "/img/exhibits/floor_2/5-1.jpg",
				"infoText": "파충류",
				"content": "몸통 전체에 표범무늬가 있으며, 주로 해안사구와 하안사구에 많이 서식하고 있다.  행동이 민첩하며, 곤충이나 애벌레 등을 먹는다.  서해안에 많이 분포하고 있으며, 해안 사구의 보호가 필요한 종이다."
			},
			{
				"infoType": "imageText",
				"title": "남생이",
				"subTitle": "Mauremys reevesii",
				"image": "/img/exhibits/floor_2/5-2.jpg",
				"infoText": "파충류",
				"content": "우리나라 토종 담수성 거북이다.  4월부터 관찰할 수 있으며, 잡식성으로 어류, 양서류, 갑각류, 수생식물 등을 먹는다. 국내에서 개체군이 감소하고 있으며, 서식지 파괴, 외래종 유입에 따른 포식, 한약재 사용으로의 불법 포획을 이유로 들 수 있다."
			},
			{
				"infoType": "imageText",
				"title": "수원청개구리",
				"subTitle": "Hyla suweonensis",
				"image": "/img/exhibits/floor_2/5-3.jpg",
				"infoText": "양서류",
				"content": "우리나라 고유종으로 청개구리와 유사하여 외형적으로 구별하기 어려우나 수컷의 경우, 목 부분이 노란색을 띠고 있어 구별할 수 있으며, 울음소리로도 구별이 가능하다. 농지개발로 인해 개체수가 급격히 감소하고 있다."
			},
			{
				"infoType": "imageText",
				"title": "비바리뱀",
				"subTitle": "Sibynophis chinensis",
				"image": "/img/exhibits/floor_2/5-4.jpg",
				"infoText": "파충류",
				"content": "우리나라에서는 제주도에만 분포한다.  숲이나 덤불 근처 초지대를 선호하며, 도마뱀류를 주요 먹이원으로 한다.  5월~7월에 많이 관찰된다."
			},
		],
	},
	{
		position: { x: 45.142063331152144, y: 3.318293965779149, z: 5.968985833144908 },
		radius: 0.5,
		infoList: [
			{
				"infoType": "imageText",
				"title": "붉은점모시나비",
				"subTitle": "Parnassius bremeri",
				"image": "/img/exhibits/floor_2/6-1.jpg",
				"infoText": "곤충류",
				"content": "날개는 백색 반투명으로 검정색 무늬가 있으며, 뒷날개에 검은테가 있는 붉은색 무늬가 2개씩 있다. 엉겅퀴와 기린초 등의 꽃 꿀을 빨며 나무가 별로 없는 산지나 평지에 서식한다. 강원도와 경상도 일부 지역에 부포한다."
			},
			{
				"infoType": "imageText",
				"title": "비단벌레",
				"subTitle": "Chrysochroa coreana",
				"image": "/img/exhibits/floor_2/6-2.jpg",
				"infoText": "곤충류",
				"content": "전체적으로 금록색 금속성 광택을 띠고 있으며 우리나라 비단벌레 중 가장 크다. 삼림지대에 서식하며 팽나무, 느티나무 등이 기주식물로 알려져 있다. "
			},
			{
				"infoType": "imageText",
				"title": "대모잠자리",
				"subTitle": "Libellula angelina",
				"image": "/img/exhibits/floor_2/6-3.jpg",
				"infoText": "곤충류",
				"content": "몸은 갈색 바탕에 등줄이 검다. 미성숙일 때에는 옅은 갈색이다가 성숙하면 점차 짙어진다. 갈대와 같은 수생식물이 많고 유기물이 풍부한 연못, 습지에 서시한다. 도시 개발과 확장에 따른 매립 및 오염으로 서식지와 개체수가 감소하고 있다. "
			},
			{
				"infoType": "imageText",
				"title": "두점박이사슴벌레",
				"subTitle": "Prosopocoilus astacoides blanchardi",
				"image": "/img/exhibits/floor_2/6-4.jpg",
				"infoText": "곤충류",
				"content": "몸은 전체적으로 갈색을 띠며 가슴 등면에 있는 2개의 점이 특징이다.  주로 활엽수 수액을 빨아먹으며 야행성이다. 서식지가 제주도로 국한되어 있고 무분별한 불법 채집으로 인해 위협받고 있다. "
			}
		],
	},

	{
		"position": { x: -1.5195276687756447, y: 1.42106888004289, z: 4.888035947342527 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "고슴도치",
				"subTitle": "Erinaceus amurensiskoreensis",
				"image": "/img/exhibits/floor_1/lobby/lobby_1.jpg",
				"infoText": "식충목 - 고슴도치과",
				"content": ""
			}
		]
	},
	{
		"position": { x: -1.8989249733592155, y: 1.456025702898741, z: 3.447988924238814 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "직박구리",
				"subTitle": "Hypsipetes amaurotis",
				"image": "/img/exhibits/floor_1/lobby/lobby_2.jpg",
				"infoText": "참새목 - 직박구리과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 0.25435104649561957, y: 1.423695633203639, z: 13.856017020328219 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "두더지",
				"subTitle": "Mogera woguracoreana",
				"image": "/img/exhibits/floor_1/lobby/lobby_3.jpg",
				"infoText": "땃지목 - 두더지과 ",
				"content": ""
			}
		]
	},
	{
		"position": { x: -0.0718595120967711, y: 1.4297562937459634, z: 12.347482694570974 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "호랑지빠귀",
				"subTitle": "Zootbera dauma",
				"image": "/img/exhibits/floor_1/lobby/lobby_4.jpg",
				"infoText": "참새목 - 지빠귀과",
				"content": ""
			}
		]
	},






	{
		"position": { x: 20.20, y: 0.58, z: 29.77 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "규화목",
				"subTitle": "Petrified wood",
				"image": "/img/exhibits/floor_1/1-1/1st_1.jpg",
				"infoText": "중생대 트라이아스기 (Triassic Period) ",
				"mapPinText": "아프리카",
				"content": ""
			}
		]
	},
	{
		"position": { x: 19.42, y: 0.60, z: 28.92 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "규화목",
				"subTitle": "Petrified wood",
				"image": "/img/exhibits/floor_1/1-1/1st_2.jpg",
				"infoText": "신생대 에오세(Eocene Epoch)",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 22.07, y: 0.56, z: 31.20 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "솔방울 화석",
				"subTitle": "Araucaria Elongata",
				"image": "/img/exhibits/floor_1/1-1/1st_3.jpg",
				"infoText": "중생대 백악기 (Cretaceous Period)",
				"mapPinText": "오스트리아",
				"content": ""
			}
		]
	},
	{
		"position": { x: 22.07, y: 0.60, z: 30.81 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "솔방울 화석",
				"subTitle": "Araucaria Elongata",
				"image": "/img/exhibits/floor_1/1-1/1st_4.jpg",
				"infoText": "중생대 백악기 (Cretaceous Period)",
				"mapPinText": "아르헨티나",
				"content": ""
			}
		]
	},
	{
		"position": { x: 20.94, y: 0.61, z: 30.97 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "식물화석",
				"subTitle": "Quercus sp.",
				"image": "/img/exhibits/floor_1/1-1/1st_5.jpg",
				"infoText": "신생대 마이오세 (Miocene Epoch)",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 17.37, y: 0.59, z: 31.29 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "은행류의 잎 화석",
				"subTitle": "Ginkgoites sp.",
				"image": "/img/exhibits/floor_1/1-1/1st_6.jpg",
				"infoText": "중생대 백악기 (Cretaceous Period)",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 17.39, y: 0.63, z: 30.83 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "은행잎 화석",
				"subTitle": "Ginkgoites sp.",
				"image": "/img/exhibits/floor_1/1-1/1st_7.jpg",
				"infoText": "중생대 백악기 (Cretaceous Period)",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 18.56, y: 0.63, z: 30.56 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "은행잎 화석",
				"subTitle": "Ginkgoites sp.",
				"image": "/img/exhibits/floor_1/1-1/1st_8.jpg",
				"infoText": "신생대 팔레오세(Paleocene Epoch)",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 20.91, y: 0.59, z: 30.54 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "참나무과 화석",
				"subTitle": "Quercus",
				"image": "/img/exhibits/floor_1/1-1/1st_9.jpg",
				"infoText": "신생대 마이오세 (Miocene Epoch)",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},






	{
		"position": { x: 16.92, y: 0.61, z: 18.63 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "함자박",
				"subTitle": "wooden bowl / 函支",
				"image": "/img/exhibits/floor_1/1-2/1st_1.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 17.02, y: 0.61, z: 18.50 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "함지박",
				"subTitle": "wooden bowl / 函支(瓢)",
				"image": "/img/exhibits/floor_1/1-2/1st_2.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 16.30, y: 0.60, z: 19.20 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "메(목메)",
				"subTitle": "Mallet",
				"image": "/img/exhibits/floor_1/1-2/1st_3.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 15.78, y: 0.76, z: 18.66 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "물레",
				"subTitle": "Spinning Wheel",
				"image": "/img/exhibits/floor_1/1-2/1st_4.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 18.97, y: 0.64, z: 17.64 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "물지게",
				"subTitle": "Water-Carrying Yoke / 汲戶",
				"image": "/img/exhibits/floor_1/1-2/1st_5.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 16.56, y: 0.76, z: 17.76 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "북",
				"subTitle": "Drum / 鼓",
				"image": "/img/exhibits/floor_1/1-2/1st_6.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 17.36, y: 0.76, z: 17.07 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "약저울(집)",
				"subTitle": " Pharmacy Scales / 藥稱",
				"image": "/img/exhibits/floor_1/1-2/1st_7.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 19.90, y: 0.64, z: 17.71 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "채반",
				"subTitle": "Wicker Tray",
				"image": "/img/exhibits/floor_1/1-2/1st_8.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 17.61, y: 0.63, z: 17.72 },
		"radius": 0.5,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "멍에(소멍에)",
				"subTitle": "Yoke / 牛駕",
				"image": "/img/exhibits/floor_1/1-2/1st_9.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},






	{
		"position": { x: 27.05, y: 1.52, z: 28.42 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "금불초 씨앗",
				"subTitle": "Inula britannica var. japonica (Thunb.)",
				"image": "/img/exhibits/floor_1/1-3/1st_1.jpg",
				"infoText": "국화과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.05, y: 1.18, z: 28.01 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "단풍나무 씨앗",
				"subTitle": "Acer palmatum Thunb. ex Murray",
				"image": "/img/exhibits/floor_1/1-3/1st_3.jpg",
				"infoText": "단풍나무과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.06, y: 1.16, z: 28.42 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "대왕송 씨앗",
				"subTitle": "Pinus palustris",
				"image": "/img/exhibits/floor_1/1-3/1st_4.jpg",
				"infoText": "소나무과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.07, y: 1.50, z: 30.93 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "도꼬마리 씨앗",
				"subTitle": "Xantbium strumarium L.",
				"image": "/img/exhibits/floor_1/1-3/1st_5.jpg",
				"infoText": "국화과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.07, y: 1.17, z: 30.50 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "등나무 씨앗",
				"subTitle": "Wistaria floribunda A.P.DC.",
				"image": "/img/exhibits/floor_1/1-3/1st_6.jpg",
				"infoText": "콩과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.07, y: 1.17, z: 30.06 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "마름 씨앗",
				"subTitle": "Trapa japonica Flerow",
				"image": "/img/exhibits/floor_1/1-3/1st_7.jpg",
				"infoText": "마름과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.06, y: 1.50, z: 30.50 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "봉선화 씨앗",
				"subTitle": "Impatiens balsamina L.",
				"image": "/img/exhibits/floor_1/1-3/1st_8.jpg",
				"infoText": "봉선화과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.05, y: 1.51, z: 28.02 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "쑥부쟁이 씨앗",
				"subTitle": "Aster yomena (Kitam.) Honda",
				"image": "/img/exhibits/floor_1/1-3/1st_9.jpg",
				"infoText": "국화과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.06, y: 1.51, z: 30.06 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "연꽃 씨앗",
				"subTitle": "Nelumbo nucifera Gaertn.",
				"image": "/img/exhibits/floor_1/1-3/1st_10.jpg",
				"infoText": "수련과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 27.07, y: 1.16, z: 30.93 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "탱자나무 씨앗",
				"subTitle": "Poncirus trifoliata Raf. ",
				"image": "/img/exhibits/floor_1/1-3/1st_11.jpg",
				"infoText": "운향과",
				"content": ""
			}
		]
	},
	{
		"position": { x: 36.15, y: 0.79, z: 30.26 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "낙우송과 솔방울 화석",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-3/1st_2.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},





	{
		"position": { x: 26.80, y: 1.90, z: 25.46 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "한대림",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-4/1.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 26.80, y: 1.89, z: 26.98 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "온대북부림",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-4/2.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 26.80, y: 1.88, z: 28.49 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "온대중부림",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-4/3.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 26.80, y: 1.88, z: 29.99 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "온대남부림",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-4/4.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 26.80, y: 1.81, z: 31.48 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "난대림",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-4/5.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},





	{
		"position": { x: 21.47, y: 0.9, z: 33.62 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "자식의 안부를 전하는 당수나무",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-5/1.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 20.08, y: 0.9, z: 33.66 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "도개비와 개암나무",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-5/2.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 18.78, y: 0.9, z: 33.64 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "등나무에 엃힌 자매의 슬픈 전설",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-5/3.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 13.07, y: 0.9, z: 33.80 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "능수버들 이야기",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-5/4.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 11.80, y: 0.9, z: 33.79 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "나도밤나무 이야기",
				"subTitle": "",
				"image": "/img/exhibits/floor_1/1-5/5.jpg",
				"limitImageHeight": false,
				"infoText": "",
				"content": ""
			}
		]
	},


	{
		"position": { x: 23.567871967623976, y: 1.0455782816545631, z: 21.665695173395303 },
		"radius": 0.8,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "'비중' 체험",
				"subTitle": "",
				"limitImageHeight": false,
				"infoText": "",
				"content": "비중이란? 4c 물의 무게와 똑같은 부피를 가진 물체의 무게와의 비를 의미해요. 일반적으로 비중이 높을수록 무거워져요"
			}
		]
	},






	{
		"position": { x: 45.06, y: 7.25, z: 5.72 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "석영ᆞ적철석",
				"subTitle": "QuartzᆞHematite",
				"image": "/img/exhibits/floor_3/3-1/3rd_1.jpg",
				"infoText": "규산염ᆞ산화광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: SiO2ᆞFe2O3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.71, y: 6.77, z: 5.78 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "섬아연석",
				"subTitle": "Sphalerite",
				"image": "/img/exhibits/floor_3/3-1/3rd_2.jpg",
				"infoText": "황화광물",
				"mapPinText": "원산지: 러시아",
				"chemicalText": "화학식: ZnS",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.96, y: 7.26, z: 5.14 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "레피도라이트(리티아운모)",
				"subTitle": "Lepidolite(Lithia mica) ",
				"image": "/img/exhibits/floor_3/3-1/3rd_3.jpg",
				"infoText": "산화광물",
				"mapPinText": "원산지: 페루",
				"chemicalText": "화학식: KLiAI2Si3O10",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.63, y: 6.76, z: 5.21 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "황철석",
				"subTitle": "Pyrite",
				"image": "/img/exhibits/floor_3/3-1/3rd_4.jpg",
				"infoText": "황화광물",
				"mapPinText": "원산지: 페루",
				"chemicalText": "화학식: FeS2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.84, y: 7.27, z: 4.57 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "석영ᆞ안티몬",
				"subTitle": "QuartzᆞAntimony",
				"image": "/img/exhibits/floor_3/3-1/3rd_5.jpg",
				"infoText": "규산염ᆞ원소광물",
				"mapPinText": "원산지: 루마니아",
				"chemicalText": "화학식: SiO2ᆞSb",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.45, y: 6.77, z: 4.68 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "휘안석ᆞ형석",
				"subTitle": "StibniteᆞFluorite",
				"image": "/img/exhibits/floor_3/3-1/3rd_6.jpg",
				"infoText": "할로겐ᆞ황화광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: Sb2S3ᆞCaF2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.69, y: 7.26, z: 3.97 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "아라고나이트",
				"subTitle": "Aragonite",
				"image": "/img/exhibits/floor_3/3-1/3rd_7.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.30, y: 6.77, z: 4.09 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "형석",
				"subTitle": "Fluorite",
				"image": "/img/exhibits/floor_3/3-1/3rd_8.jpg",
				"infoText": "할로겐광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaF2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.53, y: 7.26, z: 3.40 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "방해석",
				"subTitle": "Calcite",
				"image": "/img/exhibits/floor_3/3-1/3rd_9.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.15, y: 6.78, z: 3.55 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "형석",
				"subTitle": "Fluorite",
				"image": "/img/exhibits/floor_3/3-1/3rd_10.jpg",
				"infoText": "할로겐광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaF2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.25, y: 7.27, z: 2.84 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "방해석",
				"subTitle": "Calcite",
				"image": "/img/exhibits/floor_3/3-1/3rd_11.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.90, y: 6.78, z: 3.04 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "형석",
				"subTitle": "Fluorite",
				"image": "/img/exhibits/floor_3/3-1/3rd_12.jpg",
				"infoText": "할로겐광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaF2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 44.04, y: 7.26, z: 2.30 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "방해석",
				"subTitle": "Calcite",
				"image": "/img/exhibits/floor_3/3-1/3rd_13.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.60, y: 6.78, z: 2.42 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "형석",
				"subTitle": "Fluorite",
				"image": "/img/exhibits/floor_3/3-1/3rd_14.jpg",
				"infoText": "할로겐광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaF2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.80, y: 7.26, z: 1.73 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "망간방해석",
				"subTitle": "Manganocalcite",
				"image": "/img/exhibits/floor_3/3-1/3rd_15.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.25, y: 6.77, z: 1.69 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "자수정",
				"subTitle": "",
				"image": "/img/exhibits/floor_3/3-1/3rd_16.jpg",
				"infoText": "",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.51, y: 7.27, z: 1.21 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "백운석",
				"subTitle": "Dolomite",
				"image": "/img/exhibits/floor_3/3-1/3rd_17.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaMg(CO3)2 ",
				"content": ""
			}
		]
	},
	{
		"position": { x: 43.16, y: 7.27, z: 0.68 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "아라고나이트",
				"subTitle": "Aragonite",
				"image": "/img/exhibits/floor_3/3-1/3rd_18.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.81, y: 6.77, z: 0.82 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "스콜레사이트",
				"subTitle": "Scolecite",
				"image": "/img/exhibits/floor_3/3-1/3rd_19.jpg",
				"infoText": "규산염광물",
				"mapPinText": "원산지: 인도",
				"chemicalText": "화학식: CaAI2Si3O10ᆞ3H2O",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.85, y: 7.27, z: 0.19 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "석고",
				"subTitle": "Gypsum",
				"image": "/img/exhibits/floor_3/3-1/3rd_20.jpg",
				"infoText": "황산염광물",
				"mapPinText": "원산지: 멕시코",
				"chemicalText": "화학식: CaSO4ᆞ2H2O",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.56, y: 6.77, z: 0.36 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "석영",
				"subTitle": "Quartz",
				"image": "/img/exhibits/floor_3/3-1/3rd_21.jpg",
				"infoText": "규산염광물",
				"mapPinText": "원산지: 남아프리카",
				"chemicalText": "화학식: SiO2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.51, y: 7.27, z: -0.34 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "천청석",
				"subTitle": "Celestite",
				"image": "/img/exhibits/floor_3/3-1/3rd_22.jpg",
				"infoText": "황산염광물",
				"mapPinText": "원산지: 마다가스카르",
				"chemicalText": "화학식: SrSO4",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.21, y: 6.79, z: -0.11 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "오케나이트",
				"subTitle": "Okenite",
				"image": "/img/exhibits/floor_3/3-1/3rd_23.jpg",
				"infoText": "규산염광물",
				"mapPinText": "원산지: 인도",
				"chemicalText": "화학식: Ca10Si18O46ᆞ18H2O",
				"content": ""
			}
		]
	},
	{
		"position": { x: 42.14, y: 7.27, z: -0.87 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "망가니즈중석ᆞ석영",
				"subTitle": "HubneriteᆞQuartz",
				"image": "/img/exhibits/floor_3/3-1/3rd_24.jpg",
				"infoText": "텅스텐산염ᆞ규산염광물",
				"mapPinText": "원산지: 페루",
				"chemicalText": "화학식: MnWO4ᆞSiO2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.86, y: 6.76, z: -0.65 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "백운모",
				"subTitle": "Muscovite",
				"image": "/img/exhibits/floor_3/3-1/3rd_25.jpg",
				"infoText": "규산염광물",
				"mapPinText": "원산지: 브라질",
				"chemicalText": "화학식: K(OHF2)2AI3Si3O10",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.81, y: 7.28, z: -1.35 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "중정석",
				"subTitle": "Barite",
				"image": "/img/exhibits/floor_3/3-1/3rd_26.jpg",
				"infoText": "황산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: BaSO4",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.49, y: 6.76, z: -1.06 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "금운모",
				"subTitle": "Phlogopite",
				"image": "/img/exhibits/floor_3/3-1/3rd_27.jpg",
				"infoText": "규산염광물",
				"mapPinText": "원산지: 브라질",
				"chemicalText": "화학식: KMg3(AISi3)O10(F,OH)2",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.38, y: 7.27, z: -1.79 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "석고",
				"subTitle": "Gypsum",
				"image": "/img/exhibits/floor_3/3-1/3rd_28.jpg",
				"infoText": "황산염광물",
				"mapPinText": "원산지: 멕시코",
				"chemicalText": "화학식: CaSO4ᆞ2H2O",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.10, y: 6.78, z: -1.58 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "터키석",
				"subTitle": "Turquoise",
				"image": "/img/exhibits/floor_3/3-1/3rd_29.jpg",
				"infoText": "인산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CuAI6(PO4)4(OH)8ᆞ5H2O",
				"content": ""
			}
		]
	},
	{
		"position": { x: 41.05, y: 7.26, z: -2.32 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "알루미늄",
				"subTitle": "Aluminium",
				"image": "/img/exhibits/floor_3/3-1/3rd_30.jpg",
				"infoText": "원소광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: AI",
				"content": ""
			}
		]
	},
	{
		"position": { x: 40.71, y: 6.78, z: -1.99 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "방해석",
				"subTitle": "Calcite",
				"image": "/img/exhibits/floor_3/3-1/3rd_31.jpg",
				"infoText": "탄산염광물",
				"mapPinText": "원산지: 중국",
				"chemicalText": "화학식: CaCO3",
				"content": ""
			}
		]
	},





	{
		"position": { x: 36.14, y: 6.98, z: -6.23 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "형광광물",
				"subTitle": "Fluorescent Mineral",
				"image": "/img/exhibits/floor_3/3-1/3rd_32.jpg",
				"infoText": "",
				"content": "자외선이나 X-선, 음극선 등을 쪼이면 빛이 나는 현상을 형광이라고 하며, 이러한 현상을 보이는 광물을 이루는 원소의 원자가 이 자외선을 흡수하고, 다시 눈에 보이는 가시광선으로 내보이면서 빛이 나게된다."
			}
		]
	},






	{
		"position": { x: 35.54, y: 7.06, z: 3.66 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "규화목",
				"subTitle": "硅化木 / Petrified Wood",
				"image": "/img/exhibits/floor_3/3-2/3rd_1.jpg",
				"infoText": "신생대 플라이오세",
				"mapPinText": "인도네시아",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.72, y: 7.07, z: 3.19 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "야자열매 화석(단면)",
				"subTitle": "椰子實 化石(單面) / Palm tree(cross section)",
				"image": "/img/exhibits/floor_3/3-2/3rd_2.jpg",
				"infoText": "신생대 에오세",
				"mapPinText": "인도네시아",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.69, y: 7.06, z: 2.47 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "포플러잎 화석",
				"subTitle": "Populus",
				"image": "/img/exhibits/floor_3/3-2/3rd_3.jpg",
				"infoText": "신생대 마이오세",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.63, y: 7.07, z: 2.11 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "플라타너스 화석",
				"subTitle": "Platanus",
				"image": "/img/exhibits/floor_3/3-2/3rd_4.jpg",
				"infoText": "신생대 마이오세",
				"mapPinText": "미국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.61, y: 7.07, z: 1.69 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "느룹나뭇잎,메타세쿼이아(낙우송과)화석",
				"subTitle": "落羽松課 化石 / Zelkova Ungeri, Metasequoia Ocidentalis",
				"image": "/img/exhibits/floor_3/3-2/3rd_5.jpg",
				"infoText": "신생대 마이오세",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.68, y: 7.07, z: 0.98 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "규화목",
				"subTitle": "硅化木 / Petrified Wood ",
				"image": "/img/exhibits/floor_3/3-2/3rd_6.jpg",
				"infoText": "신생대 마이오세",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.46, y: 7.10, z: 0.50 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "매미목 화석",
				"subTitle": "蟬目化石 /  Homoptera ",
				"image": "/img/exhibits/floor_3/3-2/3rd_7.jpg",
				"infoText": "중생대 백악기",
				"mapPinText": "브라질",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.45, y: 7.10, z: 0.23 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "벌목 화석",
				"subTitle": "蜂目化石 /  Hymenoptera",
				"image": "/img/exhibits/floor_3/3-2/3rd_8.jpg",
				"infoText": "중생대 백악기",
				"mapPinText": "브라질",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.44, y: 7.11, z: -0.02 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "파리목 화석",
				"subTitle": "蠅目化石 / Diptera",
				"image": "/img/exhibits/floor_3/3-2/3rd_9.jpg",
				"infoText": "중생대 백악기",
				"mapPinText": "브라질",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.44, y: 7.10, z: -0.28 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "땅귀뚜라미 화석",
				"subTitle": "地蟋蟀 化石 / Grylloidea",
				"image": "/img/exhibits/floor_3/3-2/3rd_10.jpg",
				"infoText": "중생대 백악기",
				"mapPinText": "브라질",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.43, y: 7.10, z: -0.54 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "개미목 화석",
				"subTitle": "蟻目化石 / Ant",
				"image": "/img/exhibits/floor_3/3-2/3rd_11.jpg",
				"infoText": "중생대 백악기",
				"mapPinText": "브라질",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.55, y: 7.06, z: -1.01 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "은행잎 화석",
				"subTitle": "Ginkgo",
				"image": "/img/exhibits/floor_3/3-2/3rd_12.jpg",
				"infoText": "중생대 쥐라기",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.57, y: 7.05, z: -1.36 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "글로솝테리스",
				"subTitle": "Glossopteridales",
				"image": "/img/exhibits/floor_3/3-2/3rd_13.jpg",
				"infoText": "고생대 폐름기",
				"mapPinText": "호주",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.65, y: 7.07, z: -1.63 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "노목 화석 ",
				"subTitle": "Calamites",
				"image": "/img/exhibits/floor_3/3-2/3rd_14.jpg",
				"infoText": "고생대 폐름기",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.61, y: 7.05, z: -2.21 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "종자고사리",
				"subTitle": "Pteridospermales",
				"image": "/img/exhibits/floor_3/3-2/3rd_15.jpg",
				"infoText": "고생대 폐름기",
				"mapPinText": "한국",
				"content": ""
			}
		]
	},
	{
		"position": { x: 35.53, y: 7.04, z: -2.55 },
		"radius": 0.4,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "고사리류 화석",
				"subTitle": "Fossil Ferns",
				"image": "/img/exhibits/floor_3/3-2/3rd_16.jpg",
				"infoText": "고생대 석탄기",
				"mapPinText": "프랑스",
				"content": ""
			}
		]
	},






	{
		"position": { x: -10.68, y: 6.94, z: 2.75 },
		"radius": 1.0,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "되지빠귀",
				"subTitle": "Turdus hortulorum",
				"image": "/img/exhibits/floor_3/3-3/3rd_1.jpg",
				"infoText": "참새목 - 지빠귀과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "멧비둘기",
				"subTitle": "Streptopelia orientalis",
				"image": "/img/exhibits/floor_3/3-3/3rd_2.jpg",
				"infoText": "비둘기목 - 비둘기과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "쇠검은머리쑥새",
				"subTitle": "Emberiza yessoensis",
				"image": "/img/exhibits/floor_3/3-3/3rd_3.jpg",
				"infoText": "참새목 - 멧새과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "딱새",
				"subTitle": "Phoenicurus auroreus",
				"image": "/img/exhibits/floor_3/3-3/3rd_4.jpg",
				"infoText": "참새목 - 딱새과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "쇠딱따구리",
				"subTitle": "Dendrocopos kizuki",
				"image": "/img/exhibits/floor_3/3-3/3rd_5.jpg",
				"infoText": "딱따구리목 딱따구리과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "호랑지빠귀",
				"subTitle": "Turdus dauma ",
				"image": "/img/exhibits/floor_3/3-3/3rd_6.jpg",
				"infoText": "참새목 - 지빠귀과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "큰오색딱따구리",
				"subTitle": "Dendrocopos leucotos",
				"image": "/img/exhibits/floor_3/3-3/3rd_7.jpg",
				"infoText": "딱따구리목 - 딱따구리과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "꿩",
				"subTitle": "Phasianus colchicus ",
				"image": "/img/exhibits/floor_3/3-3/3rd_8.jpg",
				"infoText": "닭목 꿩과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "꾀꼬리",
				"subTitle": "Oriolus chinensis",
				"image": "/img/exhibits/floor_3/3-3/3rd_9.jpg",
				"infoText": "참새목 - 꾀꼬리과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "청딱따구리",
				"subTitle": "Picus canus",
				"image": "/img/exhibits/floor_3/3-3/3rd_10.jpg",
				"infoText": "딱따구리목 딱따구리과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "제비",
				"subTitle": "Hirundo rustica",
				"image": "/img/exhibits/floor_3/3-3/3rd_11.jpg",
				"infoText": "참새목 - 제비과",
				"content": ""
			},
			{
				"infoType": "imageText",
				"title": "조롱이",
				"subTitle": "Accipiter gularis gularis",
				"image": "/img/exhibits/floor_3/3-3/3rd_12.jpg",
				"infoText": "매목 - 수리과",
				"content": ""
			}
		]
	},






	{
		"position": { x: -7.38, y: 7.10, z: 0.73 },
		"radius": 0.7,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "올빼미",
				"subTitle": "Strix aluco",
				"image": "/img/exhibits/floor_3/3-4/3rd_1.jpg",
				"infoText": "올빼미목 - 올빼미과",
				"content": ""
			}
		]
	},
	{
		"position": { x: -5.39, y: 6.89, z: 11.68 },
		"radius": 0.7,
		"infoList": [
			{
				"infoType": "imageText",
				"title": "참매",
				"subTitle": "Accipiter gentilis",
				"image": "/img/exhibits/floor_3/3-4/3rd_2.jpg",
				"infoText": "매목 - 수리과",
				"content": ""
			}
		]
	},





	{
		"position": { x: -2.40, y: 1.10, z: 5.50 },
		"radius": 0.7,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/1.mp4",
				"title": "고개가 들려주는 이야기",
				"content": "",
			}
		]
	},
	{
		"position": { x: -1.46, y: 1.15, z: 11.69 },
		"radius": 0.7,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/2.mp4",
				"title": "나무와 바위가 들려주는 이야기",
				"content": "",
			}
		]
	},
	{
		"position": { x: 2.27, y: 0.75, z: 11.15 },
		"radius": 0.7,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/3.mp4",
				"title": "청댕이고개 효성바위이야기",
				"content": "",
			}
		]
	},
	{
		"position": { x: 1.77, y: 0.76, z: 7.72 },
		"radius": 0.7,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/4.mp4",
				"title": "청댕이고개 느티나무 보호수",
				"content": "",
			}
		]
	},
	{
		"position": { x: 44.89, y: 1.46, z: 24.93 },
		"radius": 1,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/5.mp4",
				"title": "숲사랑 이야기",
				"content": "",
			}
		]
	},
	{
		"position": { x: 39.03, y: 3.18, z: 0.13 },
		"radius": 1,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/6.mp4",
				"title": "생태계 파괴",
				"content": "",
			}
		]
	},
	{
		"position": { x: 37.23, y: 3.29, z: 1.92 },
		"radius": 1,
		"tagIconType": "play",
		"infoList": [
			{
				"infoType": "video",
				"videoUrl": "/video/7.mp4",
				"title": "생태계 순환",
				"content": "",
			}
		]
	},
];
