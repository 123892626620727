
export const enum ViewerStatus{
	ERROR="ERROR",
	UNINITIALIZED="UNINITIALIZED",
	INITIALIZING="INITIALIZING",
	CONNECTED="CONNECTED",
	LOADING="LOADING",
	LOADED="LOADED",
	READY="READY",
}
		
export const enum ManagerStatus{
	ERROR="ERROR",
	UNINITIALIZED="UNINITIALIZED",
	// init()호출됨
	INITIALIZING="INITIALIZING",
	// 연결됨 (sdk가져옴)
	CONNECTED="CONNECTED",
	// 로드 시작
	LOADING="LOADING",
	// 로드 완료
	LOADED="LOADED",
	// 로드 완료 & 뷰어 시작 이동 완료
	READY="READY",
}


export enum ComponentInteractionType {
	/** CLICK events */
	CLICK = 'INTERACTION.CLICK',
	/** HOVER events */
	HOVER = 'INTERACTION.HOVER',
	/** DRAG events (mousedown then move) */
	DRAG = 'INTERACTION.DRAG',
	DRAG_BEGIN = 'INTERACTION.DRAG_BEGIN',
	DRAG_END = 'INTERACTION.DRAG_END',
	POINTER_MOVE = 'INTERACTION.POINTER_MOVE',
	POINTER_BUTTON = 'INTERACTION.POINTER_BUTTON',
	SCROLL = 'INTERACTION.SCROLL',
	KEY = 'INTERACTION.KEY',
	LONG_PRESS_START = 'INTERACTION.LONG_PRESS_START',
	LONG_PRESS_END = 'INTERACTION.LONG_PRESS_END',
	MULTI_SWIPE = 'INTERACTION.MULTI_SWIPE',
	MULTI_SWIPE_END = 'INTERACTION.MULTI_SWIPE_END',
	PINCH = 'INTERACTION.PINCH',
	PINCH_END = 'INTERACTION.PINCH_END',
	ROTATE = 'INTERACTION.ROTATE',
	ROTATE_END = 'INTERACTION.ROTATE_END',
}

