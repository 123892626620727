import { isWithinBoundary } from "@/libs/xrsdk/utils/threeUtils";
import { useEffect, useState } from "react";




type UseViewModeParams = {
	currentSweep: SweepDataType | null
	zoneList: ZoneListItemType[]
}
const useCurrentZone = ({ currentSweep, zoneList }: UseViewModeParams) => {

	const [currentZone, setCurrentZone] = useState<ZoneListItemType | null>(null);

	useEffect(() => {
		setCurrentZone(() => {
			if (!currentSweep) return null;

			for (const zone of zoneList) {
				if (!zone.boundary)
					continue;

				for (const boundary of zone.boundary) {
					if (isWithinBoundary(currentSweep?.position, boundary)) {
						return zone;
					}
				}
			}

			return {
				title: "본관동",
				content: "",
			};
		});
	}, [currentSweep]);

	return currentZone;
}

export default useCurrentZone;
