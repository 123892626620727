import { MpSdk } from "@matterport/webcomponent"
import * as THREE from "three"
import { ComponentInteractionType } from "./enums"

type BooleanString = "0" | "1"

export type BaseViewerParamsType = {
	m: string
	"application-key": string

	"asset-base"?: string
	"log"?: BooleanString

	ss?: string // start sweep?
	qs?: string
} & Dict

export type BaseViewerOptionsType = {
	customOptions: {
		start?: {
			sweep: number
			rotation?: [number, number]
		}
		disableMeasurement?: boolean
		disableTagDock?: boolean
		disableTagNav?: boolean
		disableTagShare?: boolean
		hideBottomText?: boolean
		hideFullscreen?: boolean
		hidePoweredBy?: boolean
	}
	params: BaseViewerParamsType
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Dict<V = any> = {
	[key: string]: V;
};


export type XrComponentKey = string
export type XrComponentCustomProps<T = any> = Dict<T>

export type XrComponentProps = {
	sdk: MpSdk
	customProps?: XrComponentCustomProps
}
export type XrComponentStates = {
	//
}

export interface XRManagerInitialData {
	viewerOptions: BaseViewerOptionsType
}

export interface XrComponentOptionType<T, K extends keyof T = keyof T> {// extends MainAppComponentOptionList
	key: K;
	props: T[K];//Omit<T[keyof T], keyof XrComponentProps>;
}

export abstract class SceneComponent<Props extends Record<string, unknown> = Record<string, unknown>> implements MpSdk.Scene.IComponent {
	declare readonly componentType: MpSdk.Scene.IComponent["componentType"];
	declare outputs: MpSdk.Scene.IComponent["outputs"];
	declare events: MpSdk.Scene.IComponent["events"];
	declare context: MpSdk.Scene.IComponent["context"];
	declare bind: MpSdk.Scene.IComponent["bind"];
	declare bindEvent: MpSdk.Scene.IComponent["bindEvent"];
	declare notify: MpSdk.Scene.IComponent["notify"];
	declare spyOnEvent: MpSdk.Scene.IComponent["spyOnEvent"];

	abstract inputs: Props // | MpSdk.Scene.IComponent["inputs"]
	declare emits: MpSdk.Scene.IComponent["emits"]

	onInit?(): void
	onEvent?(eventType: string | ComponentInteractionType, eventData?: MpSdk.Scene.InteractionEvent | unknown): void
	onInputsUpdated?(previousInputs: this["inputs"]): void
	onTick?(tickDelta: number): void
	onDestroy?(): void


	declare root: THREE.Object3D;


	// declare onEvent: MpSdk.Scene.IComponent["onEvent"]
	// declare onInputsUpdated: MpSdk.Scene.IComponent["onInputsUpdated"]
	// declare onTick: MpSdk.Scene.IComponent["onTick"]
	// declare onDestroy: MpSdk.Scene.IComponent["onDestroy"]
}


export type MaterialLikeType = ({
	material: THREE.Material
} | {
	color: number,
	textureOptions?: THREE.MeshBasicMaterialParameters
} | {
	imageUrl: string,
	textureOptions?: THREE.MeshBasicMaterialParameters
}) & {
	userData?: Dict
};
