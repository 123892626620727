import FloorGuideComponent from "@/components/xr/FloorGuide/FloorGuideComponent";
import TagComponent from "@/components/xr/Tag/TagComponent";
import XrManager, { XrManagerProps, XrManagerState } from "@xrsdk/XrManager";

type Props = {
	//
} & XrManagerProps;

type States = {
	//
} & XrManagerState;


class UserXrManager extends XrManager<Props, States>{
	protected static possibleComponents = [
		TagComponent,
		FloorGuideComponent,
	]
}

export default UserXrManager;
